import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Router from 'vue-router'
import '@/assets/css/base.css'
// import '@/assets/css/Editor.css'
import './plugins/element.js'
import '@/assets/css/element-variables.scss'
import Print from 'vue-printjs'
import '@/assets/css/cropper.min.css'
import '@/assets/css/ImgCropping.css'
import $ from "jquery"
import '@/assets/js/cropper.min.js'
//点击图片放大
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
//富文本
import VueQuillEditor from 'vue-quill-editor'
import '@/assets/css/quill.core.css'
import '@/assets/css/quill.snow.css'
import '@/assets/css/quill.bubble.css'
//富文本样式
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
//图片懒加载
import VueLazyload from 'vue-lazyload';

/*引入echarts*/
var echarts = require('echarts');
//打印
Vue.use(Print)
Vue.use(Viewer, {
  defaultOptions: {
    zIndex: 9999
  }
});
Vue.use(VueQuillEditor)
Vue.use(VueLazyload, {
  preLoad: 1.3,
  error: 'https://timgsa.baidu.com/timg?image&quality=80&size=b9999_10000&sec=1542776461626&di=466ddd2c328768c8949cc45c012fb69b&imgtype=0&src=http%3A%2F%2Fpic.58pic.com%2F58pic%2F14%2F94%2F92%2F84g58PICtDG_1024.jpg',
  loading: 'https://timgsa.baidu.com/timg?image&quality=80&size=b9999_10000&sec=1542811938331&di=07bd8655518c367267ec8432374e30ff&imgtype=0&src=http%3A%2F%2Fimg.mp.sohu.com%2Fupload%2F20170624%2F774abf26b98a46de8119eb9a2991d524_th.png',
  attempt: 1
})
Viewer.setDefaults({
  Options: {
    "inline": true,
    "button": true,
    "navbar": true,
    "title": true,
    "toolbar": true,
    "tooltip": true,
    "movable": true,
    "zoomable": true,
    "rotatable": true,
    "scalable": true,
    "transition": true,
    "fullscreen": true,
    "keyboard": true,
    "url": "data-source"
  }
});
Vue.config.productionTip = false
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.prototype.$echarts = echarts;
Vue.use(echarts)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
