import {loadBreadData, loadLeftRoutes, loadPowerRoutes, loadUserInfo} from '../assets/js/cache'

const state = {
  //头部选中
  activeTopBar: {},
  //菜单信息
  menuList:[],
  //按钮列表
  buttonList:[],
  // 用户信息
  userInfo: {},
  //是否隐藏菜单
  is_collapse:false,
  //字典功能会被缓存起来
  tipsList:[],
  menu_id:'',//当前菜单的menu_id，传给后台做日志统计
  saveDisabled:false,//保存按钮
  baseUrl:''
}

export default state
