import * as types from './mutation-types'

/*
* 设置用户信息
* */
export const setUserInfo = function ({commit}, data) {
  commit(types.SET_USER_INFO, data)
}
/*
* 设置菜单
* */
export const setMenu = function ({commit}, data) {
  commit(types.SET_MENU, data)
}
/*
* 获取权限按钮
* */
export const setButton = function ({commit}, data) {
  commit(types.SET_BUTTON, data)
}
/*
* 设置头部高亮
* */
export const setActiveTopBar = function ({commit}, data) {
  commit(types.SET_ACTIVE_TOP_BAR, data)
}
/*
* 设置菜单收缩
* */
export const setIsCollapse = function ({commit}, data) {
  commit(types.SET_IS_COLLAPSE, data)
}
/*
* 设置字典功能缓存
* */
export const setTips = function ({commit}, data) {
  commit(types.SET_TIPS, data)
}
/*
* 设置字典功能缓存
* */
export const setMenuId = function ({commit}, data) {
  commit(types.SET_MENU_ID, data)
}
export const setDisabled = function ({commit}, data) {
  commit(types.SET_DISABLED, data)
}
export const setBase64 = function ({commit}, data) {
  commit(types.SET_BASE64, data)
}
