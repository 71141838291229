<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';

  export default {
    name: 'app',
    components: {},
    created() {},
    mounted() {},

    computed: {
      ...mapGetters(['menuList']),
    },
    methods: {
      ...mapActions(['setMenuId', 'setButton']),
    },
    watch: {
      $route(to, from) {
        // console.log('我的按钮呢')
        // this.setMenuId(to.meta.menu_id)
        let buttonCn = [];
        this.menuList.forEach((item) => {
          if (item.code == to.name) {
            buttonCn = item.buttonIdCn;
          }
          if (item.childrens && item.childrens.length > 0) {
            item.childrens.forEach((sub) => {
              if (sub.code == to.name) {
                buttonCn = sub.buttonIdCn;
              }
              if (sub.childrens && sub.childrens.length > 0) {
                sub.childrens.forEach((l) => {
                  if (l.code == to.name) {
                    buttonCn = l.buttonIdCn;
                  }
                });
              }
            });
          }
        });
        this.setButton(buttonCn);
      },
    },
  };
</script>

<style lang="scss">
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
  min-width: 1200px;
}

.login-container .el-input-group__append,
.el-input-group__prepend {
  background-color: #fff !important;
}

.el-table.another-page thead {
  display: none !important;
}
.ql-editor {
  height: 500px!important;
}
.post.ql-editor{
  width: 375px;
  height:667px!important;
  overflow-y: auto;
  border: 1px solid #ccc;
}
.ql-container.ql-snow.ql-disabled{
  background-color: #F5F7FA;
  cursor: not-allowed;
  color:#C0C4CC;
  opacity: 0.5;
}
.print-table.el-table .cell{
  font-size: 13px;
  line-height: 13px;
}
.preview-dialog{
  background: rgba(000,000,000,0.5) !important;
  text-align: center;
  .el-dialog__close .el-icon .el-icon-close{
    color:#fff !important;
    font-size: 30px !important;
  }
}
</style>
