<template>
  <div class="img-list label-content">
    <!--    <viewer :images="urlList">-->
    <div class="img-list">
      <div v-for="(item,index) in urlList" class="img-div" :key="index">
        <img v-lazy="item" alt="" class="img-show" @click="showBigImage(index,item)">
        <i class="img-delete" @click="deleteImg(index)"></i>
      </div>
      <div class="img-content" v-if="urlList.length<max" v-loading="fullscreenLoading">
        <!--图片上传-->
        <span class="add-size add-size-01" :class="{'error':error}">
                   <input type="file" @change="getFile($event)" :multiple="multiple" ref="file"
                          />
                </span>
        <div class="el-form-item__error" v-if="error">
          请上传图片
        </div>
      </div>
    </div>
    <div class="images" v-viewer="{movable: false}" v-show="false">
      <img v-for="src in urlList" :src="src" :key="src">
    </div>
  </div>
</template>

<script>
  import Http from "@/service/http";
  const aliOss = require('ali-oss')
  import {timestamp} from '@/assets/js/common'
  import md5 from 'js-md5';
  let client = new aliOss({
    // region以杭州为例（oss-cn-hangzhou），其他region按实际情况填写。
    region: 'oss-cn-hangzhou',
    // 阿里云主账号AccessKey拥有所有API的访问权限，风险很高。强烈建议您创建并使用RAM账号进行API访问或日常运维，请登录RAM控制台创建RAM账号。
    accessKeyId: 'LTAI4GLAPRf3RsejqazAasyK',
    accessKeySecret: 'umzJgjxszBsY2sYToAtXuwhTxToaKs',
    bucket: 'centra-market',
  });
  let tempCheckpoint;
  export default {
    name: "index",
    props: {
      multiple: {
        type: Boolean,
        default: false
      },
      clear: {//清空数据
        type: Boolean,
        default: false
      },
      max: {//最多张数
        type: Number,
        default: 6
      },
      parentList: {
        type: Array,
        default: () => []
      },
      type: {
        type: String,
        default: ''
      },
      value: {
        type: Number,
        default: 1
      },
      error:{
        type:Boolean,
        default:false
      }
    },
    data() {
      return {
        fullscreen: true,
        dialogVisible: false,
        fileTypes: ['jpg', 'jpeg', 'png', 'bmp'],
        fullscreenLoading: false,
        imgList: [],
        dialogImg: '',
        filesList:[]//暂存
      }
    },
    computed: {
      urlList: {
        get() {
          return this.parentList
        },
        set(val) {
          return val
        },

      }
    },
    methods: {

      showBigImage(index) {
        this.$nextTick(() => {
          const viewer = this.$el.querySelector('.images').$viewer
          viewer.view(index)
          //viewer.show()
        })
      },
      async getFile(event, flag) {//获取文件信息上传
        this.fullscreenLoading = true
        let files = event.target.files

        if (files.length > this.max || this.urlList.length >= this.max) {
          this.$message.error(`最多允许上传${this.max}张图片`)
          return false
        } else {
          for (let i = 0; i < files.length; i++) {
            let seat = ''
            let arr = files[i].name.split(".")
            seat = arr[arr.length - 1].toLowerCase();
            if (!this.fileTypes.includes(seat)) {
              this.$message.error(`${files[i].name}文件格式不支持,请重新上传`)
            } else if (seat == 'jpg' || seat == 'jpeg' || seat == 'png' || seat == 'bmp') {
              // if (files[i].size > 2097152) { //2M
              //   this.$message.error(`${files[i].name}图片大小超出2M,请重新上传`)
              // } else {
                await this.getUrl(files[i])
                // this.filesList.push(files[i])
                // this.multipartUpload(files[i], i, files)
              // }
            }

          }
          // this.$refs.file.value = null//清空后可以重新上传同张文件
          this.$emit('getImgList', this.urlList)
          this.$emit('getInfo', {value: this.value, type: this.type})
        }

      },
      async multipartUpload(fileObj, i, files) {
        this.loading = true
        let md5name = md5(timestamp())
        let type = fileObj.name.substring(fileObj.name.lastIndexOf("."));
        try {
          // object-key可以自定义为文件名（例如file.txt）或目录（例如abc/test/file.txt）的形式，实现将文件上传至当前Bucket或Bucket下的指定目录。
          let result = await client.multipartUpload(`/merchant/${md5name}${type}`, fileObj, {
            progress: function (p, checkpoint) {
              // 断点记录点。浏览器重启后无法直接继续上传，您需要手动触发上传操作。
              tempCheckpoint = checkpoint;
            },
            meta: {year: 2020, people: 'test'},
            mime: 'image/jpeg'
          })
          const url = `http://centra-market.oss-cn-beijing.aliyuncs.com/merchant/${md5name}${type}`
          // this.fileList[i].loading = false
          // this.loading = false
          this.fullscreenLoading = false
          // if (this.firstTime) {
          //   this.list[i].loading = false
          // } else {
          //   this.list[this.list.length - (files.length - i)].loading = false
          // }
          this.urlList.push(url)
          if (this.urlList.length == this.fileList.length) {
            this.$emit('getImgList', this.urlList)
            // this.$emit('getFileList', this.list)
          }
        } catch (e) {
          console.log(e);
          // this.list[i].loading = false
        }
      },
      async getUrl(file) {
        let res = await Http.uploadImg({'file': file},[], true)
        if (res.code === 200) {
          this.urlList.push(res.data.url)
          this.fullscreenLoading = false
        }
        // uploadImage(file, 'image_content').then((res) => {
        //   if (res.flag === '1') {
        //     this.urlList.push(res.data.res)
        //   }
        // })
      },
      deleteImg(idx) {
        this.urlList.splice(idx, 1)
        this.$emit('getInfo', {value: this.value, type: this.type})
        this.$emit('getFile', this.urlList)
        console.log(this.urlList)
        this.$emit('getImgList', this.urlList)

      },
      clearFn() {
        this.fileList = []
        this.urlList = []
        this.$emit('getFile', this.urlList)
      },
    },
    watch: {
      urlList(newVal) {
        if (newVal) {
          this.urlList = newVal
        }
      }
    },
    created() {
    }
  }
</script>

<style lang="scss" scoped>
  /*图片上传的css start*/
  .label-content {
    .add-size {
      display: inline-block;
      width: 70px;
      cursor: pointer;
      height: 70px;
      position: relative;
      margin-right: 10px;

      input {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        display: block;
      }

      img {
        width: 100%;
        height: 100%;
      }
    }

    .img-div {
      position: relative;
      margin-bottom: 10px;
      .img-delete {
        position: absolute;
        right: 3.5px;
        top: -7.5px;
        width: 15px;
        height: 15px;
        background: url("./delete.png") no-repeat;
        background-size: 100% 100%;
      }
    }

    .add-size-01 {
      background: url('./add.png') no-repeat;
      background-size: 70px 70px;
      &.error{
        background: url('./add-error.png') no-repeat;
      }
    }

    .img-content {
      width: 70px;
      height: 70px;
      margin-bottom: 10px;
      position: relative;

    }
  }

  .img-list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .img-show {
      width: 70px;
      height: 70px;
      margin-right: 10px;
    }
  }

  /*图片上传的css end*/
</style>
