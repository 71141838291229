//入口JS
import Vue from 'vue'
import Vuex from 'vuex'
import * as actions from './actions'
import * as getters from './getters'
import state from './state'
import mutations from './mutations'
//持久化存储vuex
import VuexPersistence from 'vuex-persist'
// const vuexLocal = new VuexPersistence({
//   storage: window.localStorage
// })
// import createLogger from 'vuex/dist/logger' //每次修改会去控制台打一个状态

Vue.use(Vuex)

// const debug = process.env.NODE_ENV !== 'production'
const store=new Vuex.Store({
  actions,
  getters,
  state,
  mutations,
  plugins:[
    new VuexPersistence({
      storage: window.sessionStorage,
      reducer: state => ({
        menuList: state.menuList, //这个就是存入localStorage的值
        buttonList:state.buttonList,
        userInfo:state.userInfo,
        tipsList:state.tipsList,
        // saveDisabled:state.saveDisabled
      })
    }).plugin

  ]
})
export default store
// export default new Vuex.Store({
//   actions,
//   getters,
//   state,
//   mutations
//   // strict: debug,
//   // plugins: debug ? [createLogger()] : []
// })
