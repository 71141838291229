// 用户信息
export const SET_USER_INFO = 'SET_USER_INFO'
export const SET_IS_COLLAPSE='SET_IS_COLLAPSE'
export const SET_MENU='SET_MENU'
export const SET_BUTTON='SET_BUTTON'
export const SET_ACTIVE_TOP_BAR='SET_ACTIVE_TOP_BAR'
export const SET_TIPS='SET_TIPS'
export const SET_MENU_ID='SET_MENU_ID'
export const SET_DISABLED='SET_DISABLED'
export const SET_BASE64='SET_BASE64'
