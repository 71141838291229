const REQUEST_API = {
  //登录
  login: {
    method: 'post',
    url: '/api/cm-auth/token'
  },
  routes: {
    method: 'post',
    url: '/api/cm-system/menu/routes'
  },
  authButtons: {
    method: 'post',
    url: '/api/cm-system/menu/buttons'
  },
  menuSubmit: {
    method: 'post',
    url: '/api/cm-system/menu/submit'
  },
  parentRoutes: {
    method: 'post',
    url: '/api/cm-system/menu/parentRoutes'
  },
  removeRoute: {
    method: 'post',
    url: '/api/cm-system/menu/remove'
  },
  deptList: {
    method: 'post',
    url: '/api/cm-system/dept/list'
  },
  deptSubmit: {
    method: 'post',
    url: '/api/cm-system/dept/submit'
  },
  removeDept: {
    method: 'post',
    url: '/api/cm-system/dept/remove'
  },
  roleList: {
    method: 'post',
    url: '/api/cm-system/role/list'
  },
  logList: {
    method: 'post',
    url: '/api/cm-log/usual/list'
  },
  roleRemove: {
    method: 'post',
    url: '/api/cm-system/role/remove'
  },
  roleSubmit: {
    method: 'post',
    url: '/api/cm-system/role/submit'
  },
  // 位置列表
  getLocationList: {
    method: 'post',
    url: '/api/cm-system/cmplace/list'
  },
  // 区域列表
  getAreaList: {
    method: 'post',
    url: '/api/cm-system/cmarea/list'
  },
  //摊位类型
  getBoothTypeList: {
    method: 'post',
    url: '/api/cm-system/cmboothtype/list'
  },
  //摊位用途
  getBoothUseList: {
    method: 'post',
    url: '/api/cm-system/cmboothuse/list'
  },
  //摊位信息
  getBoothInfoList: {
    method: 'post',
    url: '/api/cm-system/cmboothinformation/page'
  },
  //收/退费项目
  getTakereTurnList: {
    method: 'post',
    url: '/api/cm-system/cmtakereturnproject/list'
  },
  //区域配置收费项目
  getAreaConfigProjectList: {
    method: 'post',
    url: '/api/cm-system/cmplaceconfigproject/list'
  },
  //区域配置收费标准
  cmareachargerateconfigPage: {
    method: 'post',
    url: '/api/cm-system/cmplacechargerateconfig/page'
  },
  //商户经营范围设置
  getBusinesssCopeList: {
    method: 'post',
    url: '/api/cm-merchant/cmbusinessscopeconfig/list'
  },
  //取暖费设置
  getWarmPriceList: {
    method: 'post',
    url: '/api/cm-system/cmwarmpriceconfig/list'
  },
  //电卡电价设置
  getElePriceList: {
    method: 'post',
    url: '/api/cm-system/cmelectricpriceconfig/list'
  },
  //商户基础信息管理
  getBasicInfoList: {
    method: 'post',
    url: '/api/cm-merchant/cmtenantbasicinformationmanage/page'
  },
  //商户证照列表
  getCardPhotoList: {
    method: 'post',
    url: '/api/cm-system/cmtenantcardphoto/list'
  },
  roleGrant: {
    method: 'post',
    url: '/api/cm-system/role/grant'
  },
  dictList: {
    method: 'post',
    url: '/api/cm-system/dict/list'
  },
  dictSubmit: {
    method: 'post',
    url: '/api/cm-system/dict/submit'
  },
  userLists: {
    method: 'post',
    url: '/api/cm-system/page'
  },
  userSubmit: {
    method: 'post',
    url: '/api/cm-system/submit'
  },
  // 位置新增或修改
  locationSubmit: {
    method: 'post',
    url: '/api/cm-system/cmplace/submit'
  },
  // 位置删除
  locationRemove: {
    method: 'post',
    url: '/api/cm-system/cmplace/remove'
  },
  // 区域新增或修改
  areaSubmit: {
    method: 'post',
    url: '/api/cm-system/cmarea/submit'
  },
  // 区域删除
  areaRemove: {
    method: 'post',
    url: '/api/cm-system/cmarea/remove'
  },
  // 摊位新增或修改
  boothTypeSubmit: {
    method: 'post',
    url: '/api/cm-system/cmboothtype/submit'
  },
  // 摊位删除
  boothTypeRemove: {
    method: 'post',
    url: '/api/cm-system/cmboothtype/remove'
  },
  // 摊位新增或者修改
  boothUseSubmit: {
    method: 'post',
    url: '/api/cm-system/cmboothuse/submit'
  },
  // 摊位删除
  boothUseRemove: {
    method: 'post',
    url: '/api/cm-system/cmboothuse/remove'
  },
  cmbusinessscopeconfigSubmit: {
    method: 'post',
    url: '/api/cm-merchant/cmbusinessscopeconfig/submit'
  },
  cmbusinessscopeconfigRemove: {
    method: 'post',
    url: '/api/cm-merchant/cmbusinessscopeconfig/remove'
  },
  // 位置下拉列表
  getPlaceLabel: {
    method: 'post',
    url: '/api/cm-system/cmplace/getLabel'
  },
  // 区域下拉列表
  getAreaLabel: {
    method: 'post',
    url: '/api/cm-system/cmarea/getLabel'
  },
// 电卡电价新增/编辑
  cmelectricpriceconfigSubmit: {
    method: 'post',
    url: '/api/cm-system/cmelectricpriceconfig/submit'
  },
  // 电卡电价删除
  cmelectricpriceconfigRemove: {
    method: 'post',
    url: '/api/cm-system/cmelectricpriceconfig/remove'
  },
// 收/退费项目新增/编辑
  cmtakereturnprojectSubmit: {
    method: 'post',
    url: '/api/cm-system/cmtakereturnproject/submit'
  },
  // 收/退费项目删除
  cmtakereturnprojectRemove: {
    method: 'post',
    url: '/api/cm-system/cmtakereturnproject/remove'
  },
  // 区域配置收费项目新增
  cmareaconfigprojectSubmit: {
    method: 'post',
    url: '/api/cm-system/cmplaceconfigproject/submit'
  },
  // 区域配置收费项目删除
  cmareaconfigprojectRemove: {
    method: 'post',
    url: '/api/cm-system/cmplaceconfigproject/remove'
  },
  // 摊位信息新增或修改
  boothInfoSubmit: {
    method: 'post',
    url: '/api/cm-system/cmboothinformation/submit'
  },
  // 摊位信息删除
  boothInfoRemove: {
    method: 'post',
    url: '/api/cm-system/cmboothinformation/remove'
  },
  // 摊位类型下拉
  boothTypeLabel: {
    method: 'post',
    url: '/api/cm-system/cmboothtype/getLabel'
  },
  // 摊位用途下拉
  boothUseLabel: {
    method: 'post',
    url: '/api/cm-system/cmboothuse/getLabel'
  },
  // 区域配置收费标准删除
  cmareachargerateconfigRemove: {
    method: 'post',
    url: '/api/cm-system/cmplacechargerateconfig/delete'
  },
  // 证照经营范围设置
  cmcardbusinessscopePage: {
    method: 'post',
    url: '/api/cm-merchant/cmcardbusinessscope/list'
  },
  // 获取区域配置收费项
  cmareachargerateconfigItems: {
    method: 'post',
    url: '/api/cm-system/cmplacechargerateconfig/items'
  },
  // 获取区域配置表新增
  cmareachargerateconfigSave: {
    method: 'post',
    url: '/api/cm-system/cmplacechargerateconfig/save'
  },
  // 收退费项目状态修改
  cmtakereturnprojectUpdStatus: {
    method: 'post',
    url: '/api/cm-system/cmtakereturnproject/updStatus'
  },
  // 摊位信息状态修改
  cmboothinformationUpdStatus: {
    method: 'post',
    url: '/api/cm-system/cmboothinformation/updStatus'
  },
  // 摊位用途状态修改
  cmboothuseUpdStatus: {
    method: 'post',
    url: '/api/cm-system/cmboothuse/updStatus'
  },
  // 摊位类型状态修改
  cmboothtypeUpdStatus: {
    method: 'post',
    url: '/api/cm-system/cmboothtype/updStatus'
  },
  // 区域状态修改
  cmareaUpdStatus: {
    method: 'post',
    url: '/api/cm-system/cmarea/updStatus'
  },
  // 位置状态修改
  cmplaceUpdStatus: {
    method: 'post',
    url: '/api/cm-system/cmplace/updStatus'
  },
  // 证照经营新增
  cmcardbusinessscopeSubmit: {
    method: 'post',
    url: '/api/cm-merchant/cmcardbusinessscope/submit'
  },
  // 证照经营删除
  cmcardbusinessscopeRemove: {
    method: 'post',
    url: '/api/cm-merchant/cmcardbusinessscope/remove'
  },
  // 商户经营范围状态修改
  cmbusinessscopeconfigUpdStatus: {
    method: 'post',
    url: '/api/cm-merchant/cmbusinessscopeconfig/updStatus'
  },
  // 证照经营范围状态修改
  cmcardbusinessscopeUpdStatus: {
    method: 'post',
    url: '/api/cm-merchant/cmcardbusinessscope/updStatus'
  },
  //商户基础信息管理删除
  getBasicInfoRemove: {
    method: 'post',
    url: '/api/cm-merchant/cmtenantbasicinformationmanage/remove'
  },
  //商户基础信息管理
  getBasicInfoSubmit: {
    method: 'post',
    url: '/api/cm-merchant/cmtenantbasicinformationmanage/submit'
  },
  //商户经营范围下拉
  businessscopeconfigLabel: {
    method: 'post',
    url: '/api/cm-merchant/cmbusinessscopeconfig/getLabel'
  },
  //主营商品类别
  mainWareTypeLabel:{
    method: 'post',
    url: '/api/cm-merchant/cmbusinessscopeconfig/getWareTypeLabel'
  },
  //商品类别
  mainWareProTypeLabel:{
    method: 'post',
    url: '/api/cm-merchant/cmbusinessscopeconfig/getWareProTypeLabel'
  },
  //商品类别
  mainWareProTypeLabelAll:{
    method: 'post',
    url: '/api/cm-merchant/cmbusinessscopeconfig/getWareProTypeLabelAll'
  },
  //证照经营范围下拉
  cardbusinessscopeLabel: {
    method: 'post',
    url: '/api/cm-merchant/cmcardbusinessscope/getLabel'
  },
  //合同列表
  cmcontractmanage: {
    method: 'post',
    url: '/api/cm-merchant/cmcontractmanage/page'
  },
  //续签合同列表
  cmcontractrenewallog: {
    method: 'post',
    url: '/api/cm-merchant/cmcontractrenewallog/page'
  },
  //商户证照信息修改
  cmtenantcardphotoSubmit: {
    method: 'post',
    url: '/api/cm-merchant/cmtenantcardphoto/submit'
  },
  //商户证照信息批量新增
  cmtenantcardphotoSaveList: {
    method: 'post',
    url: '/api/cm-merchant/cmtenantcardphoto/saveList'
  },
  //商户证照信息详情
  getBasicInfoDetail: {
    method: 'post',
    url: '/api/cm-merchant/cmtenantbasicinformationmanage/detail'
  },
  //添加照片
  uploadImg: {
    method: 'post',
    url: '/api/cm-merchant/cmtenantcardphoto/upload'
  },
  //合同详情
  contractDetail: {
    method: 'post',
    url: '/api/cm-merchant/cmcontractmanage/detail'
  },
  //合同详情
  contractChange: {
    method: 'post',
    url: '/api/cm-merchant/cmcontractmanage/contractChange'
  },
  //签合同初始化详情
  cmcontractmanageInit: {
    method: 'post',
    url: '/api/cm-merchant/cmcontractmanage/initData'
  },
  //退租信息获取
  refundDetail: {
    method: 'post',
    url: '/api/cm-merchant/cmcontractmanage/refundDetail'
  },
  //新增合同
  cmcontractmanageSubmit: {
    method: 'post',
    url: '/api/cm-merchant/cmcontractmanage/submit'
  },
  //出租中/未出租的摊位列表
  cmboothinformationLabel: {
    method: 'post',
    url: '/api/cm-system/cmboothinformation/getLabel'
  },
  //出租中/未出租的摊位详情
  cmboothinformationDetail: {
    method: 'post',
    url: '/api/cm-system/cmboothinformation/detail'
  },
  //签合同保存订单
  saveContractOrder: {
    method: 'post',
    url: '/api/cm-merchant/cmcontractmanage/saveContractOrder'
  },
  //合同退租
  rentRefund: {
    method: 'post',
    url: '/api/cm-merchant/cmcontractmanage/rentRefund'
  },
  //财务退回费用
  cmorderPage: {
    method: 'post',
    url: '/api/cm-order/cmorder/page'
  },
  //财务退回明细
  cmorderDetail: {
    method: 'post',
    url: '/api/cm-order/cmorder/detail'
  },
  refundOrdersBySecond: {
    method: 'post',
    url: '/api/cm-order/cmorder/refundordersbysecond'
  },
  //财务退回明细
  orderItemsBySecond: {
    method: 'post',
    url: '/api/cm-order/cmorder/orderitemsbysecond'
  },
  //财务退回 修改费用
  cmorderUpdate: {
    method: 'post',
    url: '/api/cm-order/cmorder/updateConfirmStatus',
  },
  //费用录入记录列表分页
  expenseEntryList: {
    method: 'post',
    url: '/api/cm-order/cmorder/expenseEntryList',
  },
  //费用录入记录列表删除
  expenseEntryDelete: {
    method: 'post',
    url: '/api/cm-order/cmorder/expenseEntryDelete',
  },
  //插卡式电卡充值录入记录
  electricExpenseEntryList: {
    method: 'post',
    url: '/api/cm-order/cmorder/electricExpenseEntryList',
  },
  //费用录入收费项下拉列表
  gettakereturnprojectLabel: {
    method: 'post',
    url: '/api/cm-system/cmtakereturnproject/getFeeLabel',
  },
  //费用录入详情
  expenseEntryDetail: {
    method: 'post',
    url: '/api/cm-order/cmorder/expenseEntryDetail',
  },
  //费用录入
  expenseEntry: {
    method: 'post',
    url: '/api/cm-order/cmorder/expenseEntry',
  },
  //根据摊位获取合同
  getContractByBoothId: {
    method: 'post',
    url: '/api/cm-merchant/cmcontractmanage/getContractByBoothId',
  },
  //根据摊位获取上月抄表记录
  getLastMonthDgress: {
    method: 'post',
    url: '/api/cm-merchant/cmcontractmanage/getLastMonthDegress',
  },
  //修改费用录入
  expenseEntryUpdatee: {
    method: 'post',
    url: '/api/cm-order/cmorder/expenseEntryUpdate',
  },
  //费用退费记录修改
  orderUpdate: {
    method: 'post',
    url: '/api/cm-order/cmorder/update',
  },
  //查询订单明细
  getListByOrderId: {
    method: 'post',
    url: '/api/cm-order/cmorderitem/getListByOrderId',
  },
  //待收费订单列表
  getWaitPayPage: {
    method: 'post',
    url: '/api/cm-order/cmorder/waitPayPage',
  },
  //财务费用待确认
  confirmPage: {
    method: 'post',
    url: '/api/cm-order/cmorder/confirmPage',
  },
  //已收费列表
  getChargedPageList: {
    method: 'post',
    url: '/api/cm-order/cmorder/chargedPage',
  },
  //履约保证金分页列表
  getDepositPageList: {
    method: 'post',
    url: '/api/cm-order/cmorder/depositPage',
  },
  //全部收费记录
  getOrderList: {
    method: 'post',
    url: '/api/cm-order/cmorder/orderList',
  },
  //退费保存
  saveReturnOrder: {
    method: 'post',
    url: '/api/cm-order/cmorder/saveReturnOrder',
  },
  //根据摊位查询订单交费记录
  getPayOrderList: {
    method: 'post',
    url: '/api/cm-order/cmorder/getPayOrderList',
  },
  //待收费订单确认到账
  confirmPay: {
    method: 'post',
    url: '/api/cm-order/cmorder/confirmPay',
  },
  //根据摊位获取所有合同信息
  selectContractByBooth: {
    method: 'post',
    url: '/api/cm-merchant/cmcontractmanage/selectContractByBooth',
  },
  //二次退款用
  contractlist: {
    method: 'post',
    url: '/api/cm-merchant/cmcontractmanage/contractlistbysecond',
  },
  //二次退款用
  orderList: {
    method: 'post',
    url: '/api/cm-order/cmorder/orderlistbysecond',
  },
  //抄表电费导入
  electricChargeExport: {
    method: 'post',
    url: '/api/cm-order/cmorder/electricChargeExport',
  },
  //抄表电费列表
  meterReadingList: {
    method: 'post',
    url: '/api/cm-order/cmorder/meterReadingList',
  },
  //打印收退费
  initPrintData: {
    method: 'post',
    url: '/api/cm-order/cmorder/initPrintData',
  },
  //确认打印
  payAndPrint: {
    method: 'post',
    url: '/api/cm-order/cmorder/payAndPrint',
  },
  //已收费列表-明细
  selectPrintItem: {
    method: 'post',
    url: '/api/cm-order/cmorder/selectPrintItem',
  },
  //打印
  getPrintData: {
    method: 'post',
    url: '/api/cm-order/cmorder/getPrintData',
  },
  //费用录入的批量导入
  expenseEntryExport: {
    method: 'post',
    url: '/api/cm-order/cmorder/expenseEntryExport',
  },
  //下拉框
  dictionaryLabel: {
    method: 'post',
    url: '/api/cm-system/dict/dictionaryLabel',
  },
  //公共水费的列表
  cmwaterpriceconfigList: {
    method: 'post',
    url: '/api/cm-system/cmwaterpriceconfig/list',
  },
  //公共水费的列表删除
  cmwaterpriceconfigRemove: {
    method: 'post',
    url: '/api/cm-system/cmwaterpriceconfig/remove',
  },
  //公共水费新增/修改
  cmwaterpriceconfigSubmit: {
    method: 'post',
    url: '/api/cm-system/cmwaterpriceconfig/submit',
  },
  //未收费列表
  unCostPage: {
    method: 'post',
    url: '/api/cm-order/cmorder/unCostPage',
  },
  //收费日报
  getReceiveAmountList: {
    method: 'post',
    url: '/api/cm-report/cmreport/getReceiveAmountList',
  },
  //摊位出租率
  getBoothOccupancyRate: {
    method: 'post',
    url: '/api/cm-report/cmreport/getBoothOccupancyRate',
  },
  //所属期费用汇总
  getFeeCollectList: {
    method: 'post',
    url: '/api/cm-report/cmreport/getFeeCollectList',
  },
  //收费员收费统计
  getPayerFeeCollectList: {
    method: 'post',
    url: '/api/cm-report/cmreport/getPayerFeeCollectList',
  },
  //上传证照
  uploadBase64: {
    method: 'post',
    url: '/api/cm-merchant/cmtenantcardphoto/uploadBase64',
  },
  //用户下拉列表
  userLabel: {
    method: 'post',
    url: '/api/cm-system/getLabel',
  },
  //收费合计大写
  amountZh: {
    method: 'post',
    url: '/api/cm-order/cmorder/amount2Zh',
  },
  //修改密码
  updatePassword: {
    method: 'post',
    url: '/api/cm-system/update-password',
  },
  //费用日报导出
  recieveAmountListExport: {
    method: 'post',
    url: '/api/cm-report/cmreport/recieveAmountListExport',
  },
  //所属期费用汇总导出
  feeCollectExport: {
    method: 'post',
    url: '/api/cm-report/cmreport/feeCollectExport',
  },
  //收费员导出
  payerFeeCollectExport: {
    method: 'post',
    url: '/api/cm-report/cmreport/payerFeeCollectExport',
  },
  //摊位出租率导出
  boothOccupancyRateExport: {
    method: 'post',
    url: '/api/cm-report/cmreport/boothOccupancyRateExport',
  },
  //已收费导出
  chargedExport: {
    method: 'post',
    url: '/api/cm-order/cmorder/chargedExport',
  },
  //未收费导出
  unCostExport: {
    method: 'post',
    url: '/api/cm-order/cmorder/unCostExport',
  },
  //获取摊位信息列表
  boothpage: {
    method: 'post',
    url: '/api/cm-system/cmboothinformation/boothpage',
  },
  //已收费明细列表
  chargeDetail: {
    method: 'post',
    url: '/api/cm-report/cmreport/getChargedPage',
  },
  //已收费明细列表导出
  chargeDetailExport: {
    method: 'post',
    url: '/api/cm-report/cmreport/chargedPageExport',
  },
  //未收费明细列表
  unCostDetail: {
    method: 'post',
    url: '/api/cm-report/cmreport/getUnCostItemList',
  },
  //未收费明细列表导出
  unCostDetailExport: {
    method: 'post',
    url: '/api/cm-report/cmreport/unCostItemExport',
  },
  // 摊位信息详情
  boothDetail: {
    method: 'post',
    url: '/api/cm-system/cmboothinformation/boothDetail',
  },
  // 合同导出
  contractExport: {
    method: 'post',
    url: '/api/cm-merchant/cmcontractmanage/contractExport',
  },
    // 合同导出
  contractInfoExport: {
    method: 'post',
    url: '/api/cm-report/cmreport/contractInfoExport',
  },
  // 合同导出
  contractFileExport: {
    method: 'post',
    url: '/api/cm-merchant/cmcontractmanage/contractFileExport',
  },
  // 摊位信息导出
  boothInfoExport: {
    method: 'post',
    url: '/api/cm-system/cmboothinformation/boothInfoExport',
  },
  //商户信息导出
  tenantInfoExport: {
    method: 'post',
    url: '/api/cm-merchant/cmtenantbasicinformationmanage/tenantInfoExport',
  },
  //首页图
  getBoothOccupancyData: {
    method: 'post',
    url: '/api/cm-report/cmreport/getBoothOccupancyData',
  },
  // 注册用户列表
  accountList: {
    method: 'post',
    url: '/api/cm-account/account/accouninfo/accountList',
  },
  // 审核用户列表
  infoList: {
    method: 'post',
    url: '/api/cm-account/account/accouninfo/infoList',
  },
  // 审核用户
  auditAccount: {
    method: 'post',
    url: '/api/cm-account/account/accouninfo/audit',
  },
  // 公告管理
  noticeList: {
    method: 'post',
    url: '/api/cm-account/notice/list',
  },
  // 公告编辑
  noticeSave: {
    method: 'post',
    url: '/api/cm-account/notice/save',
  },
  // 车辆预约
  carSubscribeList: {
    method: 'post',
    url: '/api/cm-account/trade/carSubscribe/page',
  },
  // 车道管理列表
  laneCommandList: {
    method: 'get',
    url: '/api/cm-account/trade/laneCommand/list',
  },
  // 车道管理新增
  laneCommandSave: {
    method: 'post',
    url: '/api/cm-account/trade/laneCommand/save',
  },
  // 车道管理删除
  laneCommandRemove: {
    method: 'post',
    url: '/api/cm-account/trade/laneCommand/remove',
  },
  // 门下拉列表
  getDoorLabel: {
    method: 'post',
    url: '/api/cm-account/trade/laneCommand/getDoorLabel',
  },
  // 车道下拉列表
  getLaneLabel: {
    method: 'post',
    url: '/api/cm-account/trade/laneCommand/getLaneLabel',
  },
  // 车型下拉列表
  getCarLabel: {
    method: 'post',
    url: '/api/cm-account/trade/carWeight/getLabel',
  },
  // 车辆出门收费记录
  outPayList: {
    method: 'post',
    url: '/api/cm-account/trade/carSubscribe/outPayList',
  },
  // 已收费统计查询报表
  getContractChargedPage: {
    method: 'post',
    url: '/api/cm-report/cmreport/getContractChargedPage',
  },
  // 未收费统计查询报表
  getContractUnCostItemList: {
    method: 'post',
    url: '/api/cm-report/cmreport/getContractUnCostItemList',
  },
  // 审核历史记录
  auditList: {
    method: 'post',
    url: '/api/cm-account/account/accouninfo/auditList',
  },
  // 详情
  auditDetail: {
    method: 'post',
    url: '/api/cm-account/account/accouninfo/edit',
  },
  // 新增/编辑
  auditSave: {
    method: 'post',
    url: '/api/cm-account/account/accouninfo/save',
  },
  // 删除
  auditRemove: {
    method: 'post',
    url: '/api/cm-account/account/accouninfo/remove',
  },
  // 省市区
  getCityData: {
    method: 'post',
    url: '/api/cm-account/account/accouninfo/getCityData',
  },
  // app注册用户
  appAccountList: {
    method: 'post',
    url: '/api/cm-saleinventory/appManage/accountList',
  },
  // app场内交易记录列表
  appTradeList: {
    method: 'post',
    url: '/api/cm-saleinventory/appManage/tradeList',
  },
  // 自助签约申请列表
  paySignApplyList: {
    method: 'post',
    url: '/api/cm-saleinventory/appManage/paySignApplyList',
  },
  // 叫车列表
  carCallList: {
    method: 'post',
    url: '/api/cm-saleinventory/appManage/carCallList',
  },
  // 查询叫车服务搬运种类
  queryCarryType: {
    method: 'post',
    url: '/api/cm-saleinventory/appManage/queryCarryType',
  },
  // 新增或修改叫车服务
  saveCarCall: {
    method: 'post',
    url: '/api/cm-saleinventory/appManage/saveCarCall',
  },
  // 人员进出场列表
  faceList: {
    method: 'post',
    url: '/api/cm-account/account/accouninfo/faceList',
  },
  // 大屏列表
  cmbigscreenconfigList: {
    method: 'post',
    url: '/api/cm-system/cmbigscreenconfig/list',
  },
  // 大屏详情列表
  cmbigscreenconfigItemList: {
    method: 'post',
    url: '/api/cm-system/cmbigscreenconfig/itemList',
  },
  // 删除大屏链接
  deleteScreen: {
    method: 'post',
    url: '/api/cm-system/cmbigscreenconfig/delete',
  },
  // 生成新链接
  createScreen: {
    method: 'post',
    url: '/api/cm-system/cmbigscreenconfig/create',
  },
  // 获取安全令牌
  getAccess: {
    method: 'post',
    url: '/api/cm-system/cmbigscreenconfig/getAccess',
  },
  // 新增或修改(大屏链接详情)(大屏链接详情)
  saveItem: {
    method: 'post',
    url: '/api/cm-system/cmbigscreenconfig/saveItem',
  },
    //续租合同收费项目初始化
    initRenewalData: {
    method: 'post',
    url: '/api/cm-merchant/cmcontractmanage/initRenewalData',
  },
    //续租合同保存订单
    renewalContractOrder: {
    method: 'post',
    url: '/api/cm-merchant/cmcontractmanage/renewalContractOrder',
  },
}
export default REQUEST_API
