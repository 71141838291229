<template>
  <div class="ql-height">
    <quill-editor
      v-model="content"
      ref="myQuillEditor"
      :options="editorOption" @change="onChange"
    >
    </quill-editor>
    <input type="file" @change="getFileUpload($event,'editor')" class="upLoad"/>
  </div>

</template>

<script>
  // import {quillRedefine} from 'vue-quill-editor-upload'
  import {uploadImage} from '@/assets/js/exportExcel'

  // 富文本工具栏配置
  const toolbarOptions = [
    ["bold", "italic", "underline", "strike"], // 加粗 斜体 下划线 删除线
    ["blockquote", "code-block"], // 引用  代码块
    [{header: 1}, {header: 2}], // 1、2 级标题
    [{list: "ordered"}, {list: "bullet"}], // 有序、无序列表
    [{script: "sub"}, {script: "super"}], // 上标/下标
    [{indent: "-1"}, {indent: "+1"}], // 缩进
    // [{'direction': 'rtl'}],                         // 文本方向
    [{size: ["small", false, "large", "huge"]}], // 字体大小
    [{header: [1, 2, 3, 4, 5, 6, false]}], // 标题
    [{color: []}, {background: []}], // 字体颜色、字体背景颜色
    [{font: []}], // 字体种类
    [{align: []}], // 对齐方式
    ["clean"], // 清除文本格式
    ["link", "image"] // 链接、图片、视频
  ];
  export default {
    name: "index",
    props: {
      contentAll: {
        type: String,
        default: ""
      },
    },
    data(){
      return{
        // content:'', //富文本内容
        //富文本编辑器配置
        editorOption: {
          theme: 'snow',
          placeholder: '在这里开始写正文',
          modules: {
            toolbar: {
              container: toolbarOptions,
              // container: "#toolbar",
              handlers: {
                'image': function () {
                  // 调用iview图片上传
                  document.querySelector('.upLoad').click()
                  this.quill.format('image', false)
                }
              }
            },
          },
        },
      }
    },
    created() {
    },
    computed: {
      content: {
        get() {
          return this.contentAll
        },
        set(val) {
          return val
        },

      }
    },
    methods:{
      onChange(){
        this.$emit("inputContent", this.content);
      },
      getFileUpload(event) {//获取文件信息上传
        let file = event.target.files[0]
        const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(255, 255, 255, 0.7)'
        });
        uploadImage(file, 'image_content').then((res) => {
          if (res.flag === '1') {
            this.handleSuccess(res.data.res)
          }
          loading.close();
        })
      },
      handleSuccess(res) {
        // 获取富文本组件实例
        let quill = this.$refs.myQuillEditor.quill
        // 如果上传成功
        if (res) {
          // 获取光标所在位置
          let length = quill.getSelection().index;
          // 插入图片，res为服务器返回的图片链接地址
          quill.insertEmbed(length, 'image', res)
          // 调整光标到最后
          quill.setSelection(length + 1)
        } else {
          // 提示信息，需引入Message
          this.$message.error('图片插入失败')
        }
      },
    },
    watch: {
      content(newVal) {
        if (newVal) {
          this.content = newVal
        }
      }
    },
  }
</script>

<style scoped>
  .upLoad {
    opacity: 0;
  }
</style>
