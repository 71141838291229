import store from "@/store";
export function tipsFilter(field_name) {
  let tipsList=store.state.tipsList
  let res=''
  tipsList.forEach((item)=>{
    if(item.field_name==field_name){
      res=item.define_desc
    }
   })
  return res
}
/* js小数乘法
*@parameter arg1：被乘数（接受小数和整数）
*@parameter arg2：乘数（接受小数和整数）
*@parameter fix: 乘积保留几位（接受正负整数以及0）
*/
function accMul(arg1,arg2,fix) {
  if(!parseInt(fix)==fix)
  {
    return;
  }
  var m=0,s1=arg1.toString(),s2=arg2.toString();
  try{m+=s1.split(".")[1].length}catch(e){}
  try{m+=s2.split(".")[1].length}catch(e){}
  if(m>fix){
    return (Math.round(Number(s1.replace(".",""))*Number(s2.replace(".",""))/Math.pow(10,m-fix))/Math.pow(10,fix));
  }else if(m<=fix){
    return (Number(s1.replace(".",""))*Number(s2.replace(".",""))/Math.pow(10,m)).toFixed(fix);
  }else{
    return (arg1*arg2).toFixed(fix).toString();
  }
}
export function timestamp() {
  let year = new Date().getFullYear()
  let month = new Date().getMonth() + 1
  let d = new Date().getDate()
  let h = new Date().getHours()
  let mm = new Date().getMinutes()
  let s = new Date().getSeconds()
  let random = Math.random() * 1000
  return `${year + month + d + h + mm + s + random}`
}
export const dateFormat = {
  padLeftZero: function (str) {
    return ('00' + str).substr(str.length)
  },
  formatDate: function (date, fmt) {
    if (/(y+)/.test(fmt)) {
      fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
    }
    let o = {
      'M+': date.getMonth() + 1,
      'd+': date.getDate(),
      'h+': date.getHours(),
      'm+': date.getMinutes(),
      's+': date.getSeconds()
    }
    for (let k in o) {
      if (new RegExp(`(${k})`).test(fmt)) {
        let str = o[k] + ''
        fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? str : this.padLeftZero(str))
      }
    }
    return fmt
  }

}
export function digitUppercase(n) {
  var fraction = ['角', '分'];
  var digit = [
    '零', '壹', '贰', '叁', '肆',
    '伍', '陆', '柒', '捌', '玖'
  ];
  var unit = [
    ['元', '万', '亿'],
    ['', '拾', '佰', '仟']
  ];
  var head = n < 0 ? '负' : '';
  n = Math.abs(n);
  var s = '';
  for (var i = 0; i < fraction.length; i++) {
    s += (digit[Math.floor(n * 10 * Math.pow(10, i)) % 10] + fraction[i]).replace(/零./, '');
  }
  s = s || '整';
  n = Math.floor(n);
  for (var i = 0; i < unit[0].length && n > 0; i++) {
    var p = '';
    for (var j = 0; j < unit[1].length && n > 0; j++) {
      p = digit[n % 10] + unit[1][j] + p;
      n = Math.floor(n / 10);
    }
    s = p.replace(/(零.)*零$/, '').replace(/^$/, '零') + unit[0][i] + s;
  }
  return head + s.replace(/(零.)*零元/, '元')
      .replace(/(零.)+/g, '零')
      .replace(/^整$/, '零元整');
};

