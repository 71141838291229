
//用户模块
export const userInfo = state => state.userInfo

//菜单模块
export const menuList = state => state.menuList
export const buttonList=state=>state.buttonList
export const activeTopBar=state=>state.activeTopBar
export const is_collapse = state => state.is_collapse
export const tipsList = state => state.tipsList
export const saveDisabled = state => state.saveDisabled
//base64
export const baseUrl = state => state.baseUrl
