import axios from 'axios'
import service from './requestApi'
import router from '@/router';
import store from "@/store";
import {MessageBox, Message} from "element-ui";
import NProgress from 'nprogress'; // Progress 进度条
import 'nprogress/nprogress.css'; // Progress 进度条样式
let instance = axios.create({
  // 设置默认根地址
  baseURL: process.env.VUE_APP_API_URL,
  // 设置请求超时设置
  // timeout: 60000,
})

const Http = {};  //包裹请求方法的容器
let exportShow=false //标识是否是导出
let filename=''//导出的文件名
// 请求格式or参数的统一
for (let key in service) {
  let api = service[key]; //url method
  // async 作用：避免进入回调地狱
  Http[key] = async function (
      params, // 请求参数get:url,put,post,patch(data),delete:url
      restfulParams = [],//请求接口风格是restful的风格的，就需要单独传值加在apiUrl后面
      isFormData = false, //标识是否是from-data请求
      fileName='',//标识是否是导出
      config = {}, //配置参数
  ) {
    //放入menu_id,以便后台做日志统计
    // params={...params,log_menu_id:store.state.menu_id}
    //是否是导出
    exportShow=!!fileName//通过判断文件名来知道是否是导出
    filename=fileName//导出名字赋值
    let newParams = {};
    // content-type是否是from-data的判断
    if (params && isFormData) {
      newParams = new FormData();
      for (let i in params) {
        newParams.append(i, params[i])
      }
    } else {
      newParams = params
    }
    // 不同请求的判断
    let response = {}; // 请求的返回值
    if (api.method === 'put' || api.method === 'post' || api.method === 'patch') {
      try {

        response = await instance[api.method](`${api.url + (restfulParams.length > 0 ? '/' : '') + restfulParams.join('/')}`, newParams, exportShow?{ 'responseType': 'blob' }:config)
      } catch (e) {
        response = e
      }
    } else if (api.method === 'delete' || api.method === 'get') {
      config.params = newParams
      try {
        response = await instance[api.method](`${api.url + (restfulParams.length > 0 ? '/' : '') + restfulParams.join('/')}`, config)
      } catch (e) {
        response = e
      }
    }
    return response //返回请求的响应值
  }
}

// 拦截器的添加
instance.interceptors.request.use(config => {
  // 发起请求前做些什么
  let token = store.state.userInfo.accessToken
  let tokenType = store.state.userInfo.tokenType
  if (token) {
    config.headers['blade-auth'] = tokenType + ' ' + token
  }
  config.headers.Authorization = 'Basic Y206Y21fc2VjcmV0'
  NProgress.start()
  return config
}, () => {
  // 请求错误
})

// 响应拦截器
instance.interceptors.response.use(res => {
  // 请求成功
  NProgress.done()
  if(exportShow){
    console.log(res.data)
    const blob = new Blob([res.data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
    })
    const downloadElement = document.createElement('a');
    const href = window.URL.createObjectURL(blob);
    downloadElement.href = href;
    downloadElement.download = filename + '.xls';
    document.body.appendChild(downloadElement);
    downloadElement.click();
    document.body.removeChild(downloadElement);// 下载完成移除元素
    window.URL.revokeObjectURL(href) // 释放掉blob对象
  }
  if(res.data.code!=undefined){
    if (res.data.code == 200) {
      return res.data
    } else if (res.data.code == 401) {
      router.push({path: '/login', query: {err: 'yes', errorMsg: res.data.message}})
      store.dispatch('setDisabled', false)
      return res.data
    } else {
      //Message.error(res.data.msg)
      store.dispatch('setDisabled', false)
      return res.data
    }
  }
  
}, (err) => {
  console.log(err)
  // 请求错误
})

export default Http
