<template>
    <div class="main">
        <div id="barChart" style="width: 100%;height:400px;"></div>

    </div>
</template>

<script>



  import EButton from '@/components/EButton.js'
  import ETable from '@/components/ETable'
  import EForm from '@/components/EForm'
  import EDialog from '@/components/EDialog'
  import {mapGetters, mapActions} from 'vuex'
    import Http from "@/service/http";
  export default {
    name: 'Login',

    data() {
      return {
        tableTitle: [
          {
            label: '代理商ID',
            prop: 'agent_no',
            type: 'button'
          },
          {
            label: '公司名称',
            prop: 'company_name',
            type: 'button'
          },
        ],
        tableData: [
          {
            agent_no: 1111,
            company_name: '甚至很'
          },
          {
            agent_no: 222,
            company_name: '甚至很'
          }
        ],
        loading: false,
        dialogVisible: false,
        title: '添加按钮',
        formColumns: [
          {
            title: '待办生成时间',
            type: 'datePicker',
            property: 'name'
          },
          {
            title: '最近登录APP的时间',
            type: 'rangePicker',
            property: 'name1'
          },
          {
            title: '工人手机号',
            type: 'input',
            property: 'name2'
          },
          {
            title: '待办生成时间',
            type: 'select',
            property: 'name3'
          },
          {
            title: '类型',
            type: 'cascader',
            property: 'name4'
          },
          {
            title: '地址选择',
            type: 'cityCascader',
            property: 'name5'
          },
        ],
        area:[],
        isRentData:[],
        noRentData:[]

      }
    },
    created() {
      // this.showUserList()

    },
    mounted() {
      this.getBoothOccupancyData()
      // this.getBarChart('barChart',)


    },
    computed: {
      ...mapGetters(['menuList']),
      activeIndex() {//一级菜单选中
        return this.$route.matched[1].name
      },


    },
    watch: {
      $route: {
        handler: function (route) {
          this.redirect = route.query && route.query.redirect
        },
        immediate: true
      }
    },
    components: {EButton, ETable, EForm, EDialog},
    methods: {
      ...mapActions(['setButton']),
      async getBoothOccupancyData(){
        let res=await Http.getBoothOccupancyData({})
        if(res.code==200){
            this.area=res.data.area
            this.isRentData=res.data.isRentData
            this.noRentData=res.data.noRentData
          this.getBarChart('barChart')
        }
      },
      log() {
      },
      getClick(row) {
        this.dialogVisible = true
      },
      getBarChart(id, datas) {
        var myChart = this.$echarts.init(document.getElementById(id));
        // let myChart = this.$echarts.init(document.getElementById(id));

        var option = {
          color: ['#5caeff','#ff9c3f'],
          title: {
            text: '摊位出租情况',
          },
          tooltip: {
            trigger: 'axis'
          },
          legend: {
            data: ['已租', '未租']
          },

          calculable: true,
          xAxis: [
            {

              data: this.area
            }
          ],
          yAxis: {},
          series: [
            {
              name: '已租',
              type: 'bar',
              data: this.isRentData

            },
            {
              name: '未租',
              type: 'bar',
              data:this.noRentData

            }
          ]
        };
        myChart.clear()
        myChart.setOption(option)
      },
    }
  }
</script>


<style lang="scss" scoped>
    .main {
        padding: 20px;
        height: calc(100vh - 60px);

        font-size:30px;
    }
</style>
