<template>
  <el-form ref="form" :model="formData" :rules="formRules" :label-width="labelWidth" :label-position="labelPosition"
           size="small">
    <!--    :rules="formRule"-->
    <el-row :gutter="40">
      <el-col :span="24 / rowSize" v-for="(item, i) in formColumns" :key="i" v-if="item.show">
        <el-form-item :prop="item.property" :label="item.title">
          <!-- value -->
          <span v-if="item.type === 'value'">{{ formData[item.property] }}</span>
          <!-- dateTime -->
          <el-date-picker
                  v-if="item.type === 'datetime'"
                  v-model="formData[item.property]"
                  type="datetime"
                  placeholder="选择日期"
                  format="yyyy-MM-dd hh:mm"
                  value-format="yyyy-MM-dd hh:mm"
                  :disabled="item.disabled || false"
          >
          </el-date-picker>
          <!-- datePicker -->
          <el-date-picker
                  v-if="item.type === 'datePicker'"
                  v-model="formData[item.property]"
                  type="date"
                  placeholder="选择日期"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  :disabled="item.disabled || false"
                  :picker-options="item.pickerOptions"
                  @change="dateChange"
          >
          </el-date-picker>
          <!-- monthPicker -->
          <el-date-picker
                  v-if="item.type === 'monthPicker'"
                  v-model="formData[item.property]"
                  type="month"
                  placeholder="选择年月"
                  format="yyyy-MM"
                  value-format="yyyy-MM"
                  :disabled="item.disabled || false"
                  :picker-options="item.pickerOptions"
                  @change="dateChange"
          >
          </el-date-picker>
          <!-- rangePicker -->
          <el-date-picker
                  v-if="item.type === 'rangePicker'"
                  v-model="formData[item.property]"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
          >
          </el-date-picker>
          <!-- yearPicker-->
          <el-date-picker
                  v-if="item.type === 'year'"
                  v-model="formData[item.property]"
                  type="year"
                  format="yyyy"
                  value-format="yyyy"
                  placeholder="按年份查询"
                  :disabled="item.disabled || false"
          >
          </el-date-picker>
          <!--cascader选择任意一级选项-->
          <el-cascader
                  v-if="item.type === 'cascader'"
                  :options="item.options"
                  :props="{ checkStrictly: item.checkStrictly }"
                  clearable
                  :disabled="item.disabled || false"
                  v-model="formData[item.property]"
                  @change="getCascaderChange"
          ></el-cascader>
          <!--工种-->
          <el-cascader
                  v-if="item.type === 'professionCascader'"
                  :options="professionList"
                  :props="{ checkStrictly: item.checkStrictly }"
                  clearable
                  :disabled="item.disabled || false"
                  v-model="formData[item.property]"
                  @change="getCascaderChange"
          ></el-cascader>
          <!--cascader选择地址-->
          <el-cascader
                  v-if="item.type === 'cityCascader'"
                  placeholder="请选择地区"
                  :options="cityOptions"
                  :props="{ checkStrictly: item.checkStrictly }"
                  clearable
                  filterable
                  v-model="formData[item.property]"
                  @change="getCodeFn"
                  style="width: 100%"
          ></el-cascader>
          <el-cascader
                  v-if="item.type === 'pushCascader'"
                  placeholder="请选择地区"
                  :options="pushOptions"
                  :props="{ checkStrictly: checkStrictly }"
                  clearable
                  filterable
                  v-model="formData[item.property]"
                  @change="getCodeFn"
                  style="width: 100%"
          ></el-cascader>
          <el-cascader
                  v-if="item.type === 'areaLevelCascader'"
                  placeholder="请选择地区"
                  :options="areaLevelOptions"
                  :props="{ checkStrictly: checkStrictly }"
                  clearable
                  filterable
                  v-model="formData[item.property]"
                  @change="getCodeFn"
                  style="width: 100%"
          ></el-cascader>
          <el-cascader
              v-if="item.type === 'pzTree'"
              placeholder="请选择品种"
              :options="item.options"
              :show-all-levels=false
              :props="{ expandTrigger: 'hover',multiple : true,checkStrictly: true,emitPath:false }"
              @change="getCodeFn"
              clearable
              filterable
              v-model="formData[item.property]"
              style="width: 100%"
          ></el-cascader>
          <!-- textarea -->
          <el-input
                  v-if="item.type === 'textarea'"
                  v-model.number="formData[item.property]"
                  v-bind="item.bind"
                  :placeholder="item.placeholder || '请输入'"
                  rows="7"
                  type="textarea"
          />
          <!-- radio -->
          <div v-else-if="item.type == 'radio'" class="radio-wrapper">
            <el-radio-group v-model="formData[item.property]">
              <el-radio
                      v-for="(opt, j) in item.options"
                      :key="j"
                      :label="opt.value"
                      :disabled="item.disabled || false"
                      @change="changeRadio"
              >{{ opt.label }}
                <el-input style="width: 200px" v-if="opt.inputShow" v-model="formData[item.inputProp]"
                          :disabled="formData[item.property]!=opt.value"></el-input>
              </el-radio>
            </el-radio-group>
          </div>
          <!-- 多选框checkbox -->
          <div v-else-if="item.type == 'checkbox'" class="checkbox-wrapper">
            <el-checkbox
                    v-if="item.isAllCheck"
                    :indeterminate="item.isIndeterminate"
                    v-model="item.checkAll"
                    @change="handleCheckAllChange(item)"
            >全选
            </el-checkbox>

            <el-checkbox-group v-model="formData[item.property]">
              <el-checkbox v-for="(opt, j) in item.options" :key="j" :label="opt.button_id">{{
                opt.button_name }}
              </el-checkbox>
            </el-checkbox-group>
          </div>
          <!-- 两个input + 加减按钮 -->
          <div v-else-if="item.type === 'mutliInput'" class="mutli-input-wrapper">
            <ul class="mutli-input">
              <li v-for="(element, index) in formData[item.property]" :key="index" class="input-list">
                <div class="input-wrapper">
                  <el-input v-model="element.key" :placeholder="item.placeholder || '请输入'"/>
                  <el-input v-model="element.value" :placeholder="item.placeholder || '请输入'"/>
                </div>
                <div class="handle-btn">
                  <el-button type="primary" circle
                             @click="addConfigInput(item, formData[item.property], index)"/>
                  <el-button :disabled="item.isDelete" type="danger" circle
                             @click="cutConfigInput(item, formData[item.property], index)"/>
                </div>
              </li>
            </ul>
          </div>
          <!-- 下拉框 + 是否可以多选 -->
          <el-select
                  v-else-if="item.type === 'select'"
                  :multiple="item.multiple"
                  :disabled="item.disabled || false"
                  v-model="formData[item.property]"
                  v-bind="item.bind"
                  :placeholder="item.placeholder || '请选择'"
                  :filterable="item.filterable ? item.filterable : false"
                  @change="getChange(item.property)"
                  :popper-append-to-body="false"
          >
            <!--                        <el-option key="6666" label="全部" value=""/>-->
            <el-option v-for="(opt, j) in item.options" :key="j" :label="opt.label" :value="opt.value"/>
          </el-select>

          <div v-else-if="item.type === 'upload'" class="avatarUploader">
            <el-upload
                    :show-file-list="false"
                    :before-upload="beforeUpload('image', item.property)"
                    accept="image/gif, image/jpeg"
                    drag
                    action=""
            >
              <img v-if="formData[item.property]" :src="formData[item.property]" class="avatar"/>
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </div>
          <div v-else-if="item.type === 'uploadAudio'" class="avatarUploader">
            <el-upload :show-file-list="false" :before-upload="beforeUpload('audio', item.property)" drag
                       action="">
              <i class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
            <span v-if="formData[item.property]">{{ formData[item.property] }}</span>
          </div>
          <!-- 密码 -->
          <div v-else-if="item.type === 'password'" class="pass-box">
            <el-input v-model="formData[item.property]" v-bind="item.bind"
                      :placeholder="item.placeholder || '请输入'" type="password"/>
          </div>
          <!-- englishText -->
          <el-input
                  v-else-if="item.type === 'englishText'"
                  v-model="formData[item.property]"
                  v-bind="item.bind"
                  :placeholder="item.placeholder || '请输入'"
          />
          <!-- preText -->
          <el-input
                  v-else-if="item.type === 'preText'"
                  v-model="formData[item.property]"
                  v-bind="item.bind"
                  :placeholder="item.placeholder || '请输入'"
          >
            <template slot="prepend">/</template>
          </el-input>
          <!-- authText -->
          <el-input
                  v-else-if="item.type === 'authText'"
                  v-model="formData[item.property]"
                  v-bind="item.bind"
                  :placeholder="item.placeholder || '请输入'"
          >
          </el-input>
          <!-- input || text -->
          <el-input
                  v-else-if="item.type === 'text' || item.type === 'input'"
                  :disabled="item.disabled || false"
                  v-model="formData[item.property]"
                  v-bind="item.bind"
                  :placeholder="item.placeholder || `请输入`"
                  @blur="getBlur"
                  @keyup.enter.native="item.keyUp ? onSearch() : null"
          />
          <!--混合上传-->
          <mixinUpload
                  v-else-if="item.type === 'mixinUpload'"
                  :max="10"
                  @getImgList="getImgList($event,item.property)"
                  :parentList="formData[item.property].length > 0 ? formData[item.property] : []"
          ></mixinUpload>
          <!--高拍仪-->
          <altimeter
                  v-else-if="item.type === 'altimeter'"
                  :max="1"
                  @getImgList="getImgList"
                  :parentList="formData[item.property].length > 0 ? formData[item.property] : []"
          ></altimeter>
          <!--上传图片-->

          <imgUpLoad
                  v-else-if="item.type === 'imgUpLoad'"
                  :max="1"
                  @getImgList="getImgList($event,item.property)"
                  :parentList="formData[item.property] ? [formData[item.property]] : []"
          ></imgUpLoad>
          <!--富文本-->
          <quillEditor v-else-if="item.type === 'quillEditor'" @inputContent="inputContent"></quillEditor>
          <!--按钮-->

          <span v-else-if="item.type === 'actionBtn'">
            <el-button @click="handleClose">取 消</el-button>
            <el-button type="primary" @click="getClick">确 定</el-button>
          </span>

          <!---选择经营期限-->
          <div v-else-if="item.type === 'periods'" :disabled="item.disabled || false">
            <el-date-picker
                    v-model="beginDate"
                    type="date"
                    placeholder="选择日期"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    :picker-options="item.pickerOptions"
                    @change="beginDateChange"
                    class="w1"
            >
            </el-date-picker>
            至
            <el-radio-group @change="changeRadio" v-model="periods" class="w">
              <el-radio :label="1" :value="1" class="w2">
                <el-date-picker
                        v-model="endDate"
                        type="date"
                        placeholder="选择日期"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                        @change="endDateChange"
                >
                </el-date-picker>
              </el-radio>
              <el-radio :label="2" :value="2">长期</el-radio>
            </el-radio-group>
          </div>
        </el-form-item>
      </el-col>
      <el-col :span="48 / rowSize" v-if="optionsBtn">
        <el-form-item>
          <div class="dialog-btn">
            <!--                        <el-button @click="onReset">重置</el-button>-->
            <el-button type="primary" v-if="formColumns.length > 0" @click.native.prevent="onSearch">搜索
            </el-button>
            <el-button v-if="formColumns.length > 0" @click="resetForm">重置</el-button>
            <EButton @click="exportFile" v-if="exportShow">导出</EButton>
            <el-button type="primary" @click="exportFile" v-if="permission">导出</el-button>
            <EButton @click="exportFile1" v-if="exportShow1">合同清单导出</EButton>
            <el-button type="primary" @click="exportFile1" v-if="permission1">合同清单导出</el-button>
            <!--                        <el-button type="text" @click="showMoreFn"-->
            <!--                                   v-if="formColumns.length>7">-->
            <!--                            {{showButton}}-->
            <!--                        </el-button>-->
            <slot>
              <!--多余的按钮-->
            </slot>
          </div>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
  import Http from '@/service/http';
  import vxRule from '@/assets/js/formValidate';
  import imgUpLoad from '@/components/imgUpLoad';
  import altimeter from '@/components/altimeter';
  import quillEditor from '@/components/quillEditor';
  import EButton from '@/components/EButton';
  import mixinUpload from '@/components/mixinUpload';
  import citys from '@/assets/js/citys'
  import {exportExcel} from '@/assets/js/exportExcel';

  export default {
    name: 'index',
    props: {
      //如果是searchForm就会有展开
      searchFlag: {
        type: Boolean,
        default: false,
      },
      formRules: {
        type: Object,
        default: () => {
        },
      },
      formData: {
        type: Object,
        default: () => {
        },
      },
      /*
       * 表单域标签的宽度
       * */
      labelWidth: {
        type: String,
        default: '140px',
      },
      /*
       * 表单域标签的位置
       * */
      labelPosition: {
        type: String,
        default: 'top',
      },
      // 树状地址
      initCity: {
        default: () => {
          return [];
        },
      },
      //获取树状地址传参1:普通功能，2：统计功能（多个其他）
      type: {
        type: Number,
        default: 1,
      },
      area_code: {
        default: '',
      },
      //是否严格的遵守父子节点不互相关联
      checkStrictly: {
        type: Boolean,
        default: true,
      },
      // 需要显示的form
      formColumns: {
        type: Array,
        default: () => [],
      },
      // 是否显示重置查询等等按钮
      optionsBtn: {
        type: Boolean,
        default: true,
      },
      rowSize: {
        type: Number,
        default: 4,
      },
      exportShow: {
        type: Boolean,
        default: true,
      },
      exportShow1: {
        type: Boolean,
        default: false,
      },
      permission: {
        type: Boolean,
        default: false,
      },
      permission1: {
        type: Boolean,
        default: false,
      },
      url: {
        type: String,
        default: '',
      },
      url1: {
        type: String,
        default: '',
      },
      fileName: {
        type: String,
        default: '',
      },
      exportData: {
        type: Object,
        default: () => {
        },
      },
      fileName1: {
        type: String,
        default: '',
      },
      exportData1: {
        type: Object,
        default: () => {
        },
      },
      beginDate1: {
        type: String,
        default: '',
      },
      endDate1: {
        type: String,
        default: '',
      },
      propPeriods: {
        type: Number,
        default: 1,
      },
    },
    data() {
      return {
        professionList: [], //工种列表
        formRule: [],
        reEnglish: /^[a-zA-Z_]{5,}$/,
        reMath: /^\d{6}$/,
        rePassword: /^.{6,18}$/,
        showMore: false,
        showButton: '更多筛选条件',
        cityOptions: [],
        area_code_all: [],
        caOptions: [],
        pushOptions: [],
        areaLevelOptions: [],
        periods: this.propPeriods,
        beginDate: this.beginDate1,
        endDate: this.endDate1,
        // formRules: { //rules绑定到el-form的rules属性
        //   //必填，blur触发，使用rules中的校验规则，为空时使用自定义提示语
        //   Number: vxRule(true, "Number", "blur", "请输入正确的年龄"),
        //   Mobile: vxRule(true, "Mobile", "blur", "请输入正确的手机号"),
        //   IdCard: vxRule(true, "Number", "blur", "请输入合理的身份证号"),
        //   //选填时候，第4个参数是多余的，因为用不到
        //   //选填，blur触发，使用rules中的校验规则
        //   Float3: vxRule(false, "Float3"),
        //   Encode2: vxRule(false, "Encode2"),
        //   //选填，blur触发，使用自定义校验规则
        //   ggg: vxRule(false, val=>{
        //     return {result: /^[a-z]+$/.test(val), errMsg: "只能输入小写字母"}
        //   })
        // }
      };
    },
    components: {imgUpLoad, quillEditor, EButton, altimeter, mixinUpload},
    created() {
      this.getCityData()
      // this.formColumns.forEach((item, idx) => {
      //   if (this.searchFlag) {
      //     item['show'] = true
      //     if (idx >= 7) {
      //       item['show'] = false
      //     }
      //   }
      //
      // })
      // let areaInfo = sessionStorage.getItem('areaInfo')
      // let areaInfo2 = sessionStorage.getItem('areaInfo2')
      // // let professionList = sessionStorage.getItem('professionList')
      // if (this.area_code) {
      //   this.initCodeFn()
      // } else {
      //   if (this.type == 1 && areaInfo) {
      //     this.cityOptions = JSON.parse(areaInfo)
      //   } else if (this.type == 2 && areaInfo2) {
      //     this.cityOptions = JSON.parse(areaInfo2)
      //   } else {
      //     this.initCodeFn()
      //   }
      // }
      // this.gainContent()
      // this.pushCityOptions()
      // this.getAreaLevelOptions()
    },
    methods: {
      async getCityData() {
        let res = await Http.getCityData({})
        if (res.code == 200) {
          this.cityOptions = res.data
        }
      },
      dateChange() {
        this.$emit('dateChange');
      },
      beginDateChange(val) {
        this.$emit('beginDateChange', val);
      },
      endDateChange(val) {
        this.$emit('endDateChange', val);
      },
      getChange(prop) {
        this.$emit('getChange', prop);
      },
      getBlur() {
        this.$emit('getBlur');
      },
      resetForm() {
        this.$refs.form.resetFields();
      },
      changeRadio(val) {
        this.$emit('changeRadio', val);
      },
      // 获取岗位数据
      async gainContent() {
        let res = await Http.getProfessionList();
        if (res.flag === '1') {
          this.professionList = res.data;
        }
      },
      async exportFile() {
        Http[this.url](this.exportData, [], false, this.fileName)
      },
      async exportFile1() {
        Http[this.url1](this.exportData1, [], false, this.fileName1)
      },
      getCascaderChange() {
        this.$emit('getCascaderChange');
      },
      handleClose() {
        this.$refs.form.resetFields();
        this.$emit('handleClose');
      },
      getClick() {
        this.$emit('handleClick');
        // this.$refs.form.validate((valid) => {
        //   if (valid) {
        //     this.$emit('handleClick')
        //   } else {
        //     this.$refs.form.resetFields();
        //     return false;
        //   }
        // })
        // this.$refs.form.resetFields();
      },
      showMoreFn() {
        this.showMore = !this.showMore;
        if (this.showMore) {
          this.formColumns.forEach((item, idx) => {
            item['show'] = true;
          });
          this.showButton = '收起';
        } else {
          this.formColumns.forEach((item, idx) => {
            item['show'] = true;
            if (idx >= 7) {
              item['show'] = false;
            }
          });
          this.showButton = '更多筛选条件';
        }
      },
      search() {
      },
      onSearch() {
        this.$emit('onSearch');
      },
      onReset() {
      },
      //获取省市区三级联动的数据
      async initCodeFn() {
        let res = await Http.commonAreaOptionList({
          type: this.type,
          area_code: this.area_code,
        });
        if (res.flag == '1') {
          this.cityOptions = res.data;
          if (this.type == 1) {
            if (!this.area_code) {
              sessionStorage.setItem('areaInfo', JSON.stringify(res.data));
            }
          } else {
            sessionStorage.setItem('areaInfo2', JSON.stringify(res.data));
          }
        } else {
        }
      },
      async pushCityOptions() {
        let res = await Http.getuiAreaOptionList({});
        if (res.flag == '1') {
          this.pushOptions = res.data;
        } else {
        }
      },
      async getAreaLevelOptions() {
        let res = await Http.commonAreaOptionList({
          type: 1,
          area_level_auth: 2,
        });
        if (res.flag == '1') {
          this.areaLevelOptions = res.data;
        } else {
        }
      },
      getCodeFn() {
        if (this.area_code_all.length === 0) {
          this.area_code_all = ['']; // 传空字符串是为了接口传单个code清空时重新赋值为空
        }
        this.$emit('getCode', this.area_code_all);
      },
      getImgList(urlList, prop) {
        this.$emit('getImgList', urlList);
        this.$emit('getImgPropList', {urlList, prop});
      },
      inputContent(content) {
        this.$emit('inputContent', content);
      },
    },
    watch: {
      // 监听初始化城市数据
      area_code() {
        this.initCodeFn();
      },
    },
  };
</script>

<style scoped>
  .el-select {
    width: 100%;
  }

  /deep/ .el-form-item--small .el-form-item__label {
    color: #333;
    padding-bottom: 5px;
  }

  /deep/ .el-form-item--small.el-form-item {
    margin-bottom: 15px;
  }

  .dialog-btn {
    margin-top: 35px;
  }

  .show-more:hover {
    text-decoration: underline;
  }

  .el-date-editor.el-input,
  .el-date-editor.el-input__inner {
    width: 100%;
  }

  .el-cascader.el-cascader--small {
    width: 100%;
  }

  .el-row {
    display: flex;
    flex-wrap: wrap;
  }

  .el-radio-group.w {
    width: 55%;
  }

  .el-date-editor.el-input.el-input--small.el-input--prefix.el-input--suffix.el-date-editor--date.w1 {
    width: 30%;
  }

  label.el-radio.w2 {
    width: 55%;
  }
</style>
