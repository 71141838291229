<template>
    <div>
        <div class="flex">
            <el-button class="l-btn" type="primary" size="small" @click="dialogVisible = true">高拍仪上传 </el-button>
            <div class="upload-content">
                <el-button class="l-btn" type="primary" size="small">本地上传 </el-button>
                <input type="file" @change="getFile($event)" :multiple="multiple" ref="file"
                />
            </div>

        </div>
        <div class="img-div" v-for="(item, index) in urlList" :key="index" v-if="urlList.length > 0">
            <img class="preimg pr10" :src="item" />
            <i class="img-delete" @click="deleteImg(index)"></i>
        </div>
        <!--图片裁剪框 start-->
        <el-dialog title="上传图片" :visible.sync="dialogVisible" width="40%" v-if="dialogVisible">
            <div id="video1" class="video-box">
                <img id="video" style="width: 100%; height: 380px" />
            </div>
            <div class="tr mt20">
                <el-button style="margin-left: 10px" id="paizhao" @click="CaptureBase64Ex" type="primary">拍照 </el-button>
            </div>

            <input type="text" id="saveText" value="C:\tmp\" style="margin-left: 5px; width: 150px; text-align: left; opacity: 0" />
            <div style="display: none">
                <span>图像类型</span>
                <select onchange="SetImageType()" id="imageType">
                    <option>jpg</option>
                    <option>bmp</option>
                    <option>png</option>
                    <option>tif</option>
                    <option>ico</option>
                    <option>pdf</option>
                </select>
            </div>
        </el-dialog>

    </div>
</template>

<script>
  import $ from 'jquery';
  import Http from '@/service/http';
  // import '@/assets/js/globeVar.js'
  // import '@/assets/js/mainH5.js'
  // import '@/assets/js/WebSocket.js'
  import { OpenFile, CaptureBase64Ex, getBase64 } from '@/assets/js/singleCamera.js';
  import { mapGetters } from 'vuex';
  import EDialog from '@/components/EDialog';

  var mediaStreamTrack = null;
  export default {
    name: 'index',
    props: {
      multiple: {
        type: Boolean,
        default: false,
      },
      clear: {
        //清空数据
        type: Boolean,
        default: false,
      },
      max: {
        //最多张数
        type: Number,
        default: 6,
      },
      parentList: {
        type: Array,
        default: () => [],
      },
      type: {
        type: String,
        default: '',
      },
      value: {
        type: Number,
        default: 1,
      },
      error: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      ...mapGetters(['baseUrl']),
    },
    data() {
      return {
        dialogVisible: false,
        myImg: require('../../assets/images/login_left_bg02.png'), // 一张可访问的图片路径
        imgInfo: {}, // 存图片的宽高信息
        fileName: '',
        path: 1,
        img: '../../assets/images/login_left_bg02.png',
        urlList: [],
        fileTypes: ['jpg', 'jpeg', 'png', 'bmp'],
      };
    },
    components: {
      EDialog,
    },
    mounted() {
      $('#chakan').on('click', function () {
        OpenFile();
      });
      $('#paizhao').on('click', function () {
        Capture();
      });
      window.globalFn = (url) => {
        this.uploadBase64('data:image/jpg;base64,' + url);
      };
    },
    methods: {
      async getFile(event, flag) {//获取文件信息上传
        this.fullscreenLoading = true
        let files = event.target.files

        if (files.length > this.max || this.urlList.length >= this.max) {
          this.$message.error(`最多允许上传${this.max}张图片`)
          return false
        } else {
          for (let i = 0; i < files.length; i++) {
            let seat = ''
            let arr = files[i].name.split(".")
            seat = arr[arr.length - 1].toLowerCase();
            if (!this.fileTypes.includes(seat)) {
              this.$message.error(`${files[i].name}文件格式不支持,请重新上传`)
            } else if (seat == 'jpg' || seat == 'jpeg' || seat == 'png' || seat == 'bmp') {
              await this.getUrl(files[i])
            }

          }
          // this.$refs.file.value = null//清空后可以重新上传同张文件
          this.$emit('getImgList', this.urlList)
          this.$emit('getInfo', {value: this.value, type: this.type})
        }

      },
      async getUrl(file) {
        let res = await Http.uploadImg({'file': file},[], true)
        if (res.code === 200) {
          this.urlList.push(res.data.url)
          this.fullscreenLoading = false
        }

      },
      deleteImg(idx) {
        this.urlList.splice(idx, 1);
        this.$emit('getImgList', this.urlList);
      },
      CaptureBase64Ex() {
        CaptureBase64Ex();

      },
      async uploadBase64(base64url) {
        let res = await Http.uploadBase64({
          imageString: base64url,
          fileName: '',
          ossPath: 'cardPhoto',
        });
        if (res.code == 200) {
          this.urlList.push(res.data.url);
          this.$emit('getImgList', this.urlList);
          this.dialogVisible = false;
        }
      },
      // //图像上传
      selectImg(e) {
        let file = e.target;
        if (!file.files || !file.files[0]) {
          return;
        }
        var reader = new FileReader();
        reader.onload = function (evt) {
          var replaceSrc = evt.target.result;
          //更换cropper的图片
          $('#tailoringImg').cropper('replace', replaceSrc, false); //默认false，适应高度，不失真
        };
        reader.readAsDataURL(file.files[0]);
        mediaStreamTrack && mediaStreamTrack.stop();
        $('#video').hide();
        $('#showImg').show();
      },
    },
    watch: {
      parentList() {
        this.urlList = this.parentList;
      },
      // baseUrl(old,newVal){
      //   this.uploadBase64('data:image/jpg;base64,'+this.baseUrl)
      // },
    },
    created() {
      if (this.parentList.length > 0) {
        this.urlList = this.parentList;
        // $("#preimg").attr("src", this.parentList[0]);
      }
    },
  };
</script>

<style lang="scss" scoped>
    /*图片上传的css start*/
    .tailoring-content-one {
        line-height: 18px;
    }

    .label-content {
        .add-size {
            display: inline-block;
            width: 70px;
            cursor: pointer;
            height: 70px;
            position: relative;
            margin-right: 10px;

            input {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                opacity: 0;
                display: block;
            }

            img {
                width: 100%;
                height: 100%;
            }
        }

        .add-size-01 {
            background: url('../altimeter/add.png') no-repeat;
            background-size: 70px 70px;

            &.error {
                background: url('../altimeter/add-error.png') no-repeat;
            }
        }

        .img-content {
            width: 70px;
            height: 70px;
            margin-bottom: 10px;
            position: relative;
        }
    }

    .img-list {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        .img-show {
            width: 70px;
            height: 70px;
            margin-right: 10px;
        }
    }

    .preimg {
        width: 100px;
    }

    .lh18 {
        line-height: 18px;
    }

    .video-box {
        text-align: center;

        #video {
        }
    }

    .img-div {
        position: relative;
        margin-bottom: 10px;
        display: inline-block;
        margin-top: 10px;

        .img-delete {
            position: absolute;
            right: 5px;
            top: -7.5px;
            width: 15px;
            height: 15px;
            background: url('../altimeter/delete.png') no-repeat;
            background-size: 100% 100%;
        }
    }
    .upload-content{
        position: relative;
        margin-left: 10px;
        input {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            display: block;
        }
    }
    /*图片上传的css end*/
</style>
