import Vue from 'vue'
import {
  Button,
  Breadcrumb,
  BreadcrumbItem,
  Menu,
  Submenu,
  MenuItem,
  MenuItemGroup,
  Tooltip,
  Avatar,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Icon,
  Table,
  TableColumn,
  Dialog,
  Form,
  FormItem,
  Input,
  Radio,
  RadioGroup,
  Select,
  Option,
  Cascader,
  Message,
  Tabs,
  TabPane,
  Loading,
  Pagination,
  Row,
  Col,
  DatePicker,
  Checkbox,
  CheckboxGroup,
  MessageBox,
  Drawer,
  Upload,
  Popover,
  RadioButton,
  Tree,
  Divider,
  Link,
    Image
} from 'element-ui'

Vue.use(Button)
Vue.use(Breadcrumb)
Vue.use(BreadcrumbItem)
Vue.use(Menu)
Vue.use(Submenu)
Vue.use(MenuItem)
Vue.use(MenuItemGroup)
Vue.use(Tooltip)
Vue.use(Avatar)
Vue.use(Dropdown)
Vue.use(DropdownItem)
Vue.use(DropdownMenu)
Vue.use(Icon)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Dialog)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Input)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(Select)
Vue.use(Option)
Vue.use(Cascader)
Vue.use(RadioButton)
// Vue.use(Message)
Vue.component(Message.name, Message)
Vue.prototype.$message = Message;
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Loading)
Vue.use(Pagination)
Vue.use(Row)
Vue.use(Col)
Vue.use(DatePicker)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)
Vue.use(Drawer)
Vue.use(Upload)
Vue.prototype.$messageBox = MessageBox;
Vue.use(Popover)
Vue.use(Tree)
Vue.use(Divider)
Vue.use(Link)
Vue.use(Image)
