import store from '@/store'
export default {
  functional: true,
  render(createElement, { data, listeners,children,props }) {
    let buttonList=store.state.buttonList||[]
      console.log(buttonList)
    let button=children[0].text.replace(/\s*/g, '');
    let className='el-button el-button--primary el-button--small function-button'
    let disabledClass=' is-disabled'
    if(props.type=='primary'){
      className='el-button el-button--primary el-button--small'
    }else if(props.type=='text'){
      className='el-button el-button--text'
    }
    if(props.disabled){
      disabledClass=' is-disabled'
    }else{
      disabledClass=''
    }
    if(buttonList.includes(button)){
       button=createElement( 'button', {...data,'class':{
           [className+disabledClass]:true
         },
         attrs:{
           'disabled':props.disabled,
           'type':'button',
           // 'value':button

         },
         on: {
           click: (e)=>{
             e.preventDefault();
             e.target.blur()
             return listeners.click()
           }
         }
         }, children );

      return button
    }else{
      return button=createElement( 'button', {...data,
        'class':{
          [className+' is-disabled']:true,
        },
        attrs:{
        'disabled':true,
          'type':'button',
          // 'value':button
      },
        on: {
          click: (e)=>{
            e.preventDefault();
            return listeners.click()
          }
        }
      }, children );
    }

  }
};

