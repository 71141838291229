import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'

const _import = require('./_import_production')
Vue.use(Router)

let routes = [
  {
    path: '/',
    redirect: '/login',
  },
  {
    path: '/login',
    name: 'Login',
    component: _import('login/index'),
    meta: {
      hightLight: '/login'
    }
  },
  {
    path: '/',
    // redirect: '/index',
    name: '/',
    component: ()=>import('@/views/home/index.vue'),
    meta: {title: '', hidden: true, hightLight: '/', breadNum: 0},
    children: [
      {
        path: '/main',
        redirect: '/systemSet',
        name: 'Main',
        component: ()=>import('@/views/main/index.vue'),
        children: [
          {
            path: '/index',
            name: 'Index',
            component: ()=>import('@/views/index/index.vue'),
            children: [],
            meta:{
              menu_name:'首页'
            }
          },
          {
            path: '/boothList',
            name: 'boothList',
            component: ()=>import('@/views/boothList/boothList.vue'),
            children: [],
            meta:{
              menu_name:'摊位信息查询'
            }
          },
          {
            path: '/boothList/boothDetail',
            name: 'boothList',
            component: ()=>import('@/views/boothList/boothDetail.vue'),
            children: [],
            meta:{
              menu_name:'摊位信息详情'
            }
          },
          {
            path: '/systemSet',
            name: 'SystemSet',
            redirect: '/systemSet/limit/accountList',
            component: { // 这时路由页面正常显示, 但这时会嵌套一层router-view; 如果把component注释掉, 页面就空了
              render: h => h('router-view')
            },
            meta:{
              menu_name: '系统设置'
            },
            children: [
              {
                path: '/systemSet/menu/index',
                name: 'SystemSetIndex',
                component: ()=>import('@/views/system/menu/index.vue'),
                meta:{
                  menu_name:'按钮列表'
                }

              },
              {
                path: '/systemSet/menu/menuSet',
                name: 'SystemMenuSet',
                component: ()=>import('@/views/system/menu/menuSet.vue'),
                meta:{
                  menu_name:'菜单列表'
                }
              },
              {
                path: '/systemSet/limit/roleList',
                name: 'RoleList',
                component: ()=>import('@/views/system/limitSet/roleList.vue'),
                meta:{
                  menu_name:'角色列表'
                }
              },
              {
                path: '/logoper/list',
                name: 'LogOper',
                component: ()=>import('@/views/system/limitSet/loglist.vue'),
                meta:{
                  menu_name:'操作日志'
                }
              },
              {
                path: '/systemSet/limit/deptList',
                name: 'DeptList',
                component: ()=>import('@/views/system/limitSet/deptList.vue'),
                meta:{
                  menu_name:'部门列表'
                }
              },{
                path: '/systemSet/limit/accountList',
                name: 'AccountList',
                component: ()=>import('@/views/system/limitSet/accountList.vue'),
                meta:{
                  menu_name:'用户列表'
                }
              },
              {
                path: '/realSystemSet/keyWordSet',
                name: 'KeyWordSet',
                component: ()=>import('@/views/system/realSystem/keyWordSet.vue'),
                meta:{
                  menu_name:'字典列表'
                }
              },
            ]
          },
          {
            path: '/configManage',
            name: 'ConfigManage',
            redirect: '/configManage/location',
            component: { // 这时路由页面正常显示, 但这时会嵌套一层router-view; 如果把component注释掉, 页面就空了
              render: h => h('router-view')
            },
            meta:{
              menu_name:'配置管理'
            },
            children: [
              {
                path: '/configManage/location',
                name: 'LocationSet',
                component: ()=>import('@/views/configManage/location.vue'),
                meta:{
                  menu_name:'位置'
                }
              },
              {
                path: '/configManage/area',
                name: 'AreaSet',
                component: ()=>import('@/views/configManage/area.vue'),
                meta:{
                  menu_name:'区域'
                }
              },
              {
                path: '/configManage/shopType',
                name: 'ShopType',
                component: ()=>import('@/views/configManage/shopType.vue'),
                meta:{
                  menu_name:'摊位类型'
                }
              },{
                path: '/configManage/shopUse',
                name: 'ShopUse',
                component: ()=>import('@/views/configManage/shopUse.vue'),
                meta:{
                  menu_name:'摊位用途'
                }
              },
              {
                path: '/configManage/shopInfo',
                name: 'ShopInfo',
                component: ()=>import('@/views/configManage/shopInfo.vue'),
                meta:{
                  menu_name:'摊位信息'
                }
              },
              {
                path: '/configManage/chargesProject',
                name: 'ChargesProject',
                component: ()=>import('@/views/configManage/chargesProject.vue'),
                meta:{
                  menu_name:'收/退费项目'
                }
              },
              {
                path: '/configManage/areaChargesProject',
                name: 'AreaChargesProject',
                component: ()=>import('@/views/configManage/areaChargesProject.vue'),
                meta:{
                  menu_name:'位置配置收费项目'
                }
              },
              {
                path: '/configManage/areaChargesSet',
                name: 'AreaChargesSet',
                component: ()=>import('@/views/configManage/areaChargesSet.vue'),
                meta:{
                  menu_name:'位置配置收费标准'
                }
              },
              {
                path: '/configManage/businessSet',
                name: 'BusinessSet',
                component: ()=>import('@/views/configManage/businessSet.vue'),
                meta:{
                  menu_name:'商户经营范围设置'
                }
              },
              {
                path: '/configManage/licenceSet',
                name: 'LicenceSet',
                component: ()=>import('@/views/configManage/licenceSet.vue'),
                meta:{
                  menu_name:'证照经营范围设置'
                }
              },
              {
                path: '/configManage/heatingBillSet',
                name: 'HeatingBillSet',
                component: ()=>import('@/views/configManage/heatingBillSet.vue'),
                meta:{
                  menu_name:'取暖费设置'
                }
              },
              {
                path: '/configManage/electrovalencySet',
                name: 'ElectrovalencySet',
                component: ()=>import('@/views/configManage/electrovalencySet.vue'),
                meta:{
                  menu_name:'电价设置'
                }
              },
              {
                path: '/configManage/waterCharge',
                name: 'WaterCharge',
                component: ()=>import('@/views/configManage/waterCharge.vue'),
                meta:{
                  menu_name:'公用水费收费区间配置'
                }
              },
            ]
          },
          {
            path: '/shopManage',
            name: 'ShopManage',
            redirect: '/shopManage/shopBasicInfo',
            component: { // 这时路由页面正常显示, 但这时会嵌套一层router-view; 如果把component注释掉, 页面就空了
              render: h => h('router-view')
            },
            meta:{
              menu_name:'商户管理'
            },
            children: [
              {
                path: '/shopManage/shopBasicInfo',
                name: 'ShopBasicInfo',
                component: ()=>import('@/views/shopManage/shopBasicInfo.vue'),
                meta:{
                  menu_name:'商户基础信息管理'
                },
                children:[

                ]
              },
              {
                path: '/shopManage/shopBasicInfoAdd',
                name: 'ShopBasicInfoAdd',
                component: ()=>import('@/views/shopManage/shopBasicInfoAdd.vue'),
                meta:{
                  menu_name:'商户基础信息录入',
                  highLight:'ShopBasicInfo',
                  parent_menu:'商户基础信息管理',
                  parent_route:'ShopBasicInfo'
                }
              },
              {
                path: '/shopManage/shopLicenceAdd',
                name: 'ShopLicenceAdd',
                component: ()=>import('@/views/shopManage/shopLicenceAdd.vue'),
                meta:{
                  menu_name:'商户证照添加',
                  highLight:'ShopBasicInfo',
                  parent_menu:'商户基础信息管理',
                  parent_route:'ShopBasicInfo'
                }
              },
              {
                path: '/shopManage/shopBasicInfoDetail',
                name: 'ShopBasicInfoDetail',
                component: ()=>import('@/views/shopManage/shopBasicInfoDetail.vue'),
                meta:{
                  menu_name:'查看商户基础信息',
                  highLight:'ShopBasicInfo',
                  parent_menu:'商户基础信息管理',
                  parent_route:'ShopBasicInfo'
                }
              },
              {
                path: '/shopManage/signContract',
                name: 'SignContract',
                component: ()=>import('@/views/shopManage/signContract.vue'),
                meta:{
                  menu_name:'签合同',
                  highLight:'ShopBasicInfo',
                  parent_menu:'商户基础信息管理',
                  parent_route:'ShopBasicInfo'
                }
              },
            ]
          },
          {
            path: '/contract',
            name: 'Contract',
            redirect: '/contractList',
            component: { // 这时路由页面正常显示, 但这时会嵌套一层router-view; 如果把component注释掉, 页面就空了
              render: h => h('router-view')
            },
            meta:{
              menu_name:'合同'
            },
            children: [
              {
                path: '/contractList',
                name: 'ContractList',
                component: ()=>import('@/views/contract/contractList.vue'),
                meta:{
                  menu_name:'合同列表'
                },
                children:[
                ]
              },
              {
                path: '/contract/contractUpdate',
                name: 'ContractUpdate',
                component: ()=>import('@/views/contract/contractUpdate.vue'),
                meta:{
                  menu_name:'合同信息变更',
                  highLight:'ContractList',
                  parent_menu:'合同列表',
                  parent_route:'ContractList'
                }
              },
              {
                path: '/contract/contractDetail',
                name: 'ContractDetail',
                component: ()=>import('@/views/contract/contractDetail.vue'),
                meta:{
                  menu_name:'合同详情',
                  highLight:'ContractList',
                  parent_menu:'合同列表',
                  parent_route:'ContractList'
                }
              },
              {
                path: '/contract/contractLicenceAdd',
                name: 'ContractLicenceAdd',
                component: ()=>import('@/views/contract/contractLicenceAdd.vue'),
                meta:{
                  menu_name:'添加证照',
                  highLight:'ContractList',
                  parent_menu:'合同列表',
                  parent_route:'ContractList'
                }
              },
              {
                path: '/contract/refundRent',
                name: 'RefundRent',
                component: ()=>import('@/views/contract/refundRent.vue'),
                meta:{
                  menu_name:'退租',
                  highLight:'ContractList',
                  parent_menu:'合同列表',
                  parent_route:'ContractList'
                }
              },
                {
                    path: '/contract/ReSignContract',
                    name: 'ReSignContract',
                    component: ()=>import('@/views/contract/reSignContract.vue'),
                    meta:{
                        menu_name:'续租',
                        highLight:'ContractList',
                        parent_menu:'合同列表',
                        parent_route:'ContractList'
                    }
                },
              {
                path: '/contractFile',
                name: 'ContractFile',
                component: ()=>import('@/views/contract/contractFile.vue'),
                meta:{
                  menu_name:'合同归档'
                }
              },
              {
                path: '/contractRenewalLog',
                name: 'ContractRenewalLog',
                component: ()=>import('@/views/contract/contractRenewalLog.vue'),
                meta:{
                  menu_name:'续签日志'
                }
              },
            ]
          },
          {
            path: '/financeManage',
            name: 'FinanceManage',
            redirect: '/financeConfirm',
            component: { // 这时路由页面正常显示, 但这时会嵌套一层router-view; 如果把component注释掉, 页面就空了
              render: h => h('router-view')
            },
            meta:{
              menu_name:'收费管理'
            },
            children: [
              {
                path: '/financeBack',
                name: 'FinanceBack',
                component: ()=>import('@/views/contract/financeBack.vue'),
                meta:{
                  menu_name:'财务退回费用'
                }
              },
              {
                path: '/financeConfirm',
                name: 'FinanceConfirm',
                component: ()=>import('@/views/financeManage/financeConfirm.vue'),
                meta:{
                  menu_name:'财务费用确认'
                },
                children:[

                ]
              },
              {
                path: '/guaranteeList',
                name: 'GuaranteeList',
                component: ()=>import('@/views/financeManage/guaranteeList.vue'),
                meta:{
                  menu_name:'履约保证金'
                }
              },
              {
                path: '/chargeList',
                name: 'ChargeList',
                component: ()=>import('@/views/financeManage/chargeList.vue'),
                meta:{
                  menu_name:'全部收费记录'
                }
              },
              {
                path: '/refundList',
                name: 'RefundList',
                component: ()=>import('@/views/financeManage/refundList.vue'),
                meta:{
                  menu_name:'退费'
                }
              },
              {
                path: '/secondrefundlist',
                name: 'SecondRefundList',
                component: ()=>import('@/views/financeManage/secondrefundlist.vue'),
                meta:{
                  menu_name:'二次退费'
                }
              },
            ]
          },
          {
            path: '/service',
            name: 'Service',
            redirect: '/costAdd',
            component: { // 这时路由页面正常显示, 但这时会嵌套一层router-view; 如果把component注释掉, 页面就空了
              render: h => h('router-view')
            },
            meta:{
              menu_name:'费用录入管理'
            },
            children: [
              {
                path: '/costAdd',
                name: 'CostAdd',
                component: ()=>import('@/views/service/costAdd.vue'),
                meta:{
                  menu_name:'费用录入'
                },
                children:[

                ]
              },
              {
                path: '/costList',
                name: 'CostList',
                component: ()=>import('@/views/service/costList.vue'),
                meta:{
                  menu_name:'费用录入记录'
                },

              },
              {
                path: '/costEdit',
                name: 'CostEdit',
                component: ()=>import('@/views/service/costEdit.vue'),
                meta:{
                  menu_name:'修改费用',
                  highLight:'CostList',
                  parent_menu:'费用录入记录',
                  parent_route:'CostList'
                },
              },
              {
                path: '/electricityList',
                name: 'ElectricityList',
                component: ()=>import('@/views/service/electricityList.vue'),
                meta:{
                  menu_name:'插电式电卡充值'
                }
              },
              {
                path: '/electricityWrite',
                name: 'ElectricityWrite',
                component: ()=>import('@/views/service/electricityWrite.vue'),
                meta:{
                  menu_name:'查表电费'
                }
              },
            ]
          },
          {
            path: '/transferConfirm',
            name: 'TransferConfirm',
            redirect: '/confirmOrderList',
            component: { // 这时路由页面正常显示, 但这时会嵌套一层router-view; 如果把component注释掉, 页面就空了
              render: h => h('router-view')
            },
            meta:{
              menu_name:'转账确认'
            },
            children: [
              {
                path: '/confirmOrderList',
                name: 'ConfirmOrderList',
                component: ()=>import('@/views/transferConfirm/confirmOrderList.vue'),
                meta:{
                  menu_name:'待确认收费订单',
                },
                children:[

                ]
              },
            ]
          },
          {
            path: '/charge',
            name: 'Charge',
            redirect: '/chargeRefund',
            component: { // 这时路由页面正常显示, 但这时会嵌套一层router-view; 如果把component注释掉, 页面就空了
              render: h => h('router-view')
            },
            meta:{
              menu_name:'收费'
            },
            children: [
              {
                path: '/chargeRefund',
                name: 'ChargeRefund',
                component: ()=>import('@/views/charge/chargeRefund.vue'),
                meta:{
                  menu_name:'收费/退费',
                },
                children:[

                ]
              },
              {
                path: '/chargedList',
                name: 'ChargedList',
                component: ()=>import('@/views/charge/chargedList.vue'),
                meta:{
                  menu_name:'已收费列表'
                },

              },
              {
                path: '/unChargedList',
                name: 'UnChargedList',
                component: ()=>import('@/views/charge/unChargedList.vue'),
                meta:{
                  menu_name:'未收费列表'
                },

              },
              {
                path: '/costEdit',
                name: 'CostEdit',
                component: ()=>import('@/views/service/costEdit.vue'),
                meta:{
                  menu_name:'修改费用',
                  highLight:'CostList',
                  parent_menu:'费用录入记录',
                  parent_route:'CostList'
                },
              },
              {
                path: '/electricityList',
                name: 'ElectricityList',
                component: ()=>import('@/views/service/electricityList.vue'),
                meta:{
                  menu_name:'插电式电卡充值录入记录'
                }
              },
            ]
          },
          {
            path: '/dataReport',
            name: 'DataReport',
            redirect: '/feeDaily',
            component: { // 这时路由页面正常显示, 但这时会嵌套一层router-view; 如果把component注释掉, 页面就空了
              render: h => h('router-view')
            },
            meta:{
              menu_name:'数据报表'
            },
            children: [
              {
                path: '/feeDaily',
                name: 'FeeDaily',
                component: ()=>import('@/views/dataReport/feeDaily.vue'),
                meta:{
                  menu_name:'收费日报'
                },
                children:[

                ]
              },
              {
                path: '/unChargeRefundList',
                name: 'UnChargeRefundList',
                component: ()=>import('@/views/dataReport/unChargeRefundList.vue'),
                meta:{
                  menu_name:'未收费明细查询'
                }
              },
              {
                path: '/unChargeRefundCountList',
                name: 'UnChargeRefundCountList',
                component: ()=>import('@/views/dataReport/unChargeRefundCountList.vue'),
                meta:{
                  menu_name:'未收费统计查询'
                }
              },
              {
                path: '/unChargeRefundList/unchargeDetails',
                name: 'unchargeDetails',
                component: ()=>import('@/views/dataReport/unchargeDetails.vue'),
                meta:{
                  menu_name:'未收费明细查询详情'
                }
              },
              {
                path: '/chargeRefundList',
                name: 'ChargeRefundList',
                component: ()=>import('@/views/dataReport/chargeRefundList.vue'),
                meta:{
                  menu_name:'已收费明细查询'
                }
              },
              {
                path: '/chargeRefundCountList',
                name: 'ChargeRefundCountList',
                component: ()=>import('@/views/dataReport/chargeRefundCountList.vue'),
                meta:{
                  menu_name:'已收费统计查询'
                }
              },
              {
                path: '/chargeRefundList/chargeDetails',
                name: 'chargeDetails',
                component: ()=>import('@/views/dataReport/chargeDetails.vue'),
                meta:{
                  menu_name:'已收费明细查询详情'
                }
              },

              {
                path: '/boothRentalRateList',
                name: 'BoothRentalRateList',
                component: ()=>import('@/views/dataReport/boothRentalRateList.vue'),
                meta:{
                  menu_name:'摊位出租率'
                }
              },
              {
                path: '/chargeStatistics',
                name: 'ChargeStatistics',
                component: ()=>import('@/views/dataReport/chargeStatistics.vue'),
                meta:{
                  menu_name:'收费员收费统计'
                }
              },
              {
                path: '/costSummary',
                name: 'CostSummary',
                component: ()=>import('@/views/dataReport/costSummary.vue'),
                meta:{
                  menu_name:'所属期费用汇总'
                }
              },
            ]
          },
          {
            path: '/wechat',
            name: 'Wechat',
            redirect: '/feeDaily',
            component: { // 这时路由页面正常显示, 但这时会嵌套一层router-view; 如果把component注释掉, 页面就空了
              render: h => h('router-view')
            },
            meta:{
              menu_name:'小程序管理'
            },
            children: [
              {
                path: '/registerList',
                name: 'RegisterList',
                component: ()=>import('@/views/wechat/registerList.vue'),
                meta:{
                  menu_name:'注册用户列表'
                },
                children:[

                ]
              },
              {
                path: '/examineHistory',
                name: 'ExamineHistory',
                component: ()=>import('@/views/wechat/examineHistory.vue'),
                meta:{
                  menu_name:'审核历史记录'
                },
                children:[

                ]
              },
              {
                path: '/centerExamine',
                name: 'CenterExamine',
                component: ()=>import('@/views/wechat/centerExamine.vue'),
                meta:{
                  menu_name:'中央市场办公人员审核记录'
                },
                children:[

                ]
              },
              {
                path: '/examineUser',
                name: 'ExamineUser',
                component: ()=>import('@/views/wechat/examineUser.vue'),
                meta:{
                  menu_name:'供应商'
                },
                children:[

                ]
              },
              {
                path: '/examineBuyer',
                name: 'ExamineBuyer',
                component: ()=>import('@/views/wechat/examineBuyer.vue'),
                meta:{
                  menu_name:'采购商'
                },
                children:[

                ]
              },
              {
                path: '/examineCar',
                name: 'ExamineCar',
                component: ()=>import('@/views/wechat/examineCar.vue'),
                meta:{
                  menu_name:'摆渡车'
                },
                children:[

                ]
              },
              {
                path: '/examineCarDriver',
                name: 'ExamineCarDriver',
                component: ()=>import('@/views/wechat/examineCarDriver.vue'),
                meta:{
                  menu_name:'司机/伙计'
                },
                children:[

                ]
              },
              {
                path: '/examineCenter',
                name: 'ExamineCenter',
                component: ()=>import('@/views/wechat/examineCenter.vue'),
                meta:{
                  menu_name:'中央市场办公人员'
                },
                children:[

                ]
              },
              {
                path: '/examineSecurity',
                name: 'ExamineSecurity',
                component: ()=>import('@/views/wechat/examineSecurity.vue'),
                meta:{
                  menu_name:'保安/保洁'
                },
                children:[

                ]
              },
              {
                path: '/noticeManage',
                name: 'NoticeManage',
                component: ()=>import('@/views/wechat/noticeManage.vue'),
                meta:{
                  menu_name:'公告管理'
                },
                children:[

                ]
              },
          ]
          },
          {
            path: '/outInManage',
            name: 'OutInManage',
            redirect: '/feeDaily',
            component: { // 这时路由页面正常显示, 但这时会嵌套一层router-view; 如果把component注释掉, 页面就空了
              render: h => h('router-view')
            },
            meta:{
              menu_name:'进出场管理'
            },
            children: [
              {
                path: '/carBookList',
                name: 'CarBookList',
                component: ()=>import('@/views/outInManage/carBookList.vue'),
                meta:{
                  menu_name:'车辆预约进场记录'
                },
                children:[

                ]
              },
              {
                path: '/carChargeList',
                name: 'CarChargeList',
                component: ()=>import('@/views/outInManage/carChargeList.vue'),
                meta:{
                  menu_name:'车辆出门收费列表'
                },
                children:[

                ]
              },
              {
                path: '/userInList',
                name: 'UserInList',
                component: ()=>import('@/views/outInManage/userInList.vue'),
                meta:{
                  menu_name:'人员进场记录'
                },
                children:[

                ]
              },
              {
                path: '/macManage',
                name: 'MacManage',
                component: ()=>import('@/views/outInManage/macManage.vue'),
                meta:{
                  menu_name:'通道MAC管理'
                },
                children:[

                ]
              },
            ]
          },
          {
            path: '/appManage',
            name: 'AppManage',
            redirect: '/feeDaily',
            component: { // 这时路由页面正常显示, 但这时会嵌套一层router-view; 如果把component注释掉, 页面就空了
              render: h => h('router-view')
            },
            meta:{
              menu_name:'App管理'
            },
            children: [
              {
                path: '/appRegisterList',
                name: 'AppRegisterList',
                component: ()=>import('@/views/appManage/appRegisterList.vue'),
                meta:{
                  menu_name:'注册用户列表'
                },
                children:[

                ]
              },
              {
                path: '/appTradingRecord',
                name: 'AppTradingRecord',
                component: ()=>import('@/views/appManage/appTradingRecord.vue'),
                meta:{
                  menu_name:'场内交易记录'
                },
                children:[

                ]
              },{
                path: '/autoSignApply',
                name: 'AutoSignApply',
                component: ()=>import('@/views/appManage/autoSignApply.vue'),
                meta:{
                  menu_name:'自动签约申请'
                },
                children:[

                ]
              },{
                path: '/carService',
                name: 'CarService',
                component: ()=>import('@/views/appManage/carService.vue'),
                meta:{
                  menu_name:'叫车服务'
                },
                children:[

                ]
              },
            ]
          },
          {
            path: '/slidePic',
            name: 'SlidePic',
            redirect: '/slidePicList',
            component: { // 这时路由页面正常显示, 但这时会嵌套一层router-view; 如果把component注释掉, 页面就空了
              render: h => h('router-view')
            },
            meta: {
              menu_name: '大屏'
            },
            children: [
              {
                path: '/slidePicList',
                name: 'SlidePicList',
                component: () => import('@/views/slidePic/slidePicList.vue'),
                meta: {
                  menu_name: '大屏后台'
                },
                children: []
              },
              {
                path: '/slidePicDetail',
                name: 'SlidePicDetail',
                component: () => import('@/views/slidePic/slidePicDetail.vue'),
                meta: {
                  menu_name: '编辑大屏后台设置',
                  highLight:'SlidePicList',
                  parent_menu:'大屏后台',
                  parent_route:'SlidePicList'
                },
                children: []
              },
            ]
          },
          {
            path: '/404',
            component: _import('404'),
            name: 'NotFound',
            meta: {title: '404', hidden: true, menuActive: '/404', leaf: true, breadNum: 0}
          }
        ]
      },


    ]
  },
  {
    path: '*',
    redirect: '/404'
  }
]
const router = new Router({
  routes,
  scrollBehavior(to, from, savedPosition) {
    return {x: 0, y: 0}
  }
});
router.beforeEach((to, from, next) => {
  // sessionStorage.setItem('areaInfo', '')
  // NProgress.start();
  setTimeout(()=>{
    let permissionList=store.state.menuList
    let isPermission = false
    permissionList.forEach((v) => {
      // 判断跳转的页面是否在权限列表中
      if(v.childrens&&v.childrens.length>0){
        v.childrens.forEach((sub)=>{
          if (sub.code == to.name) {
            isPermission = true
          }
          if(sub.childrens&&sub.childrens.length>0){
            sub.childrens.forEach((l)=>{
              if (l.code == to.name) {
                isPermission = true
              }
            })
          }
        })
      }
      if (v.code == to.name) {
        isPermission = true
      }
    })
    // 没有权限时跳转到401页面
    if (!isPermission&&to.name!=='Login') {

      console.log('没有权限')
      next({path: "/login"})
    } else {
      next();
    }
  },500)

});



export default router
