<template>
  <div class="flex-align-start">
    <el-button class="l-btn" type="primary" size="small" @click="dialogVisible = true" :disabled="urlList.length >= 4">高拍仪上传 </el-button>
    <div class="img-div" v-for="(item, index) in urlList" :key="index" v-if="urlList.length > 0">
      <img class="preimg pl10" :src="item" />
      <i class="img-delete" @click="deleteImg(index)"></i>
    </div>

    <!--图片裁剪框 start-->
    <el-dialog title="上传图片" :visible.sync="dialogVisible" width="40%" v-if="dialogVisible">
      <div id="video1" class="video-box">
        <img id="video" style="width: 100%; height: 380px" />
      </div>
      <div class="tr mt20">
        <el-button style="margin-left: 10px" id="paizhao" @click="CaptureBase64Ex" type="primary">拍照 </el-button>
      </div>

      <input type="text" id="saveText" value="C:\tmp\" style="margin-left: 5px; width: 150px; text-align: left; opacity: 0" />
      <div style="display: none">
        <span>图像类型</span>
        <select onchange="SetImageType()" id="imageType">
          <option>jpg</option>
          <option>bmp</option>
          <option>png</option>
          <option>tif</option>
          <option>ico</option>
          <option>pdf</option>
        </select>
      </div>

      <!--            <button style="margin-left: 10px;" id="chakan" @click="openFile">查看文件</button>-->
    </el-dialog>
  </div>
</template>

<script>
  import $ from 'jquery';
  import Http from '@/service/http';
  // import '@/assets/js/globeVar.js'
  // import '@/assets/js/mainH5.js'
  // import '@/assets/js/WebSocket.js'
  import { OpenFile, CaptureBase64Ex, getBase64 } from '@/assets/js/singleCamera.js';
  import { mapGetters } from 'vuex';
  import EDialog from '@/components/EDialog';

  var mediaStreamTrack = null;
  export default {
    name: 'index',
    props: {
      multiple: {
        type: Boolean,
        default: false,
      },
      clear: {
        //清空数据
        type: Boolean,
        default: false,
      },
      max: {
        //最多张数
        type: Number,
        default: 6,
      },
      parentList: {
        type: Array,
        default: () => [],
      },
      type: {
        type: String,
        default: '',
      },
      value: {
        type: Number,
        default: 1,
      },
      error: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      ...mapGetters(['baseUrl']),
    },
    data() {
      return {
        dialogVisible: false,
        myImg: require('../../assets/images/login_left_bg02.png'), // 一张可访问的图片路径
        imgInfo: {}, // 存图片的宽高信息
        fileName: '',
        path: 1,
        img: '../../assets/images/login_left_bg02.png',
        urlList: [],
      };
    },
    components: {
      EDialog,
    },
    mounted() {
      $('#chakan').on('click', function () {
        OpenFile();
      });
      $('#paizhao').on('click', function () {
        Capture();
      });
      window.globalFn = (url) => {
        this.uploadBase64('data:image/jpg;base64,' + url);
      };
      // this.parentList=[require('./add.png'),require('./add.png')]
    },
    methods: {
      deleteImg(idx) {
        this.urlList.splice(idx, 1);
        this.$emit('getImgList', this.urlList);
      },
      CaptureBase64Ex() {
        //filename
        CaptureBase64Ex();
        // setTimeout(()=>{
        //   if(this.baseUrl){
        //     this.uploadBase64('data:image/jpg;base64,'+this.baseUrl)
        //   }
        // },2000)
      },
      async uploadBase64(base64url) {
        let res = await Http.uploadBase64({
          imageString: base64url,
          fileName: '',
          ossPath: 'cardPhoto',
        });
        if (res.code == 200) {
          this.urlList.push(res.data.url);
          this.$emit('getImgList', this.urlList);
          this.dialogVisible = false;
        }
      },
      // //图像上传
      selectImg(e) {
        let file = e.target;
        if (!file.files || !file.files[0]) {
          return;
        }
        var reader = new FileReader();
        reader.onload = function (evt) {
          var replaceSrc = evt.target.result;
          //更换cropper的图片
          $('#tailoringImg').cropper('replace', replaceSrc, false); //默认false，适应高度，不失真
        };
        reader.readAsDataURL(file.files[0]);
        mediaStreamTrack && mediaStreamTrack.stop();
        $('#video').hide();
        $('#showImg').show();
      },
    },
    watch: {
      parentList() {
        this.urlList = this.parentList;
      },
      // baseUrl(old,newVal){
      //   this.uploadBase64('data:image/jpg;base64,'+this.baseUrl)
      // },
    },
    created() {
      if (this.parentList.length > 0) {
        // $("#preimg").attr("src", this.parentList[0]);
      }
    },
  };
</script>

<style lang="scss" scoped>
/*图片上传的css start*/
.tailoring-content-one {
  line-height: 18px;
}

.label-content {
  .add-size {
    display: inline-block;
    width: 70px;
    cursor: pointer;
    height: 70px;
    position: relative;
    margin-right: 10px;

    input {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      display: block;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

  .add-size-01 {
    background: url('./add.png') no-repeat;
    background-size: 70px 70px;

    &.error {
      background: url('./add-error.png') no-repeat;
    }
  }

  .img-content {
    width: 70px;
    height: 70px;
    margin-bottom: 10px;
    position: relative;
  }
}

.img-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .img-show {
    width: 70px;
    height: 70px;
    margin-right: 10px;
  }
}

.preimg {
  width: 100px;
}

.lh18 {
  line-height: 18px;
}

.video-box {
  text-align: center;

  #video {
  }
}

.img-div {
  position: relative;
  margin-bottom: 10px;
  display: inline-block;

  .img-delete {
    position: absolute;
    right: -6px;
    top: -7.5px;
    width: 15px;
    height: 15px;
    background: url('./delete.png') no-repeat;
    background-size: 100% 100%;
  }
}

/*图片上传的css end*/
</style>
