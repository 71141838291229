const _import = require('@/router/_import_production')
import Index from '@/views/index/index.vue'
export default function Menufilter(data) {
  let routes = [
    {
      path: '/',
      // redirect: '/index',
      name: '/',
      component: ()=>import('@/views/home/index.vue'),
      meta: {title: '', hidden: true, hightLight: '/', breadNum: 0},
      children: [
        {
          path: '/main',
          redirect: '/systemSet',
          name: 'Main',
          component: ()=>import('@/views/main/index.vue'),
          children: []
        },

        // {
        //   path: '/index',
        //   name: 'Index',
        //   component: ()=>import('@/views/index/index.vue'),
        //   meta: {title: '首页', hidden: true, button_id_cn:data[0].button_id_cn},
        //
        // },
      ]
    },
  ]
  let arr = []
  let exceptIndexList=[]
  data.forEach((item, index) => {
    menuBack(item,arr,index)
    // exceptIndexList.push(item)
    // if(item.route_name=='Index'){
    //   routes[0].children.push(
    //       {
    //         path: '/index',
    //         name: 'Index',
    //         component: ()=>import('@/views/index/index.vue'),
    //         meta: {title:item.menu_name, hidden: item.is_show,button_id_cn:item.button_id_cn,menu_id:item.menu_id},
    //
    //       },
    //   )
    // }else{
    //   exceptIndexList.push(item)
    //
    // }

  })
  function menuBack(item,arr,index){
    arr.push({
      path: item.route_path,
      name: item.route_name,
      component: item.route_component?() => import(`@/views/${item.route_component}.vue`): {render: h => h('router-view')},
      redirect: item.children?item.children[0].route_path:'',
      meta: {title: item.menu_name, hidden: item.is_show,button_id_cn:item.button_id_cn,menu_id:item.menu_id},
      children: []
    })
    if(item.children&&item.children.length > 0){
      item.children.forEach((sub,i)=>{
        menuBack(sub,arr[index].children,index)
      })

      // item.children.forEach((sub,)=>{})
      // arr.push({
      //   path: item.route_path,
      //   name: item.route_name,
      //   component: item.route_component?() => import(`@/views/${item.route_component}.vue`): {render: h => h('router-view')},
      //   redirect: item.children?item.children[0].route_path:'',
      //   meta: {title: item.menu_name, hidden: item.is_show,button_id_cn:item.button_id_cn,menu_id:item.menu_id},
      //   children: []
      // })
    }

  }
  exceptIndexList.forEach((item,index)=>{
    arr.push({
      path: item.route_path,
      name: item.route_name,
      component: item.route_component?() => import(`@/views/${item.route_component}.vue`): {render: h => h('router-view')},
      redirect: item.children?item.children[0].route_path:'',
      meta: {title: item.menu_name, hidden: item.is_show,button_id_cn:item.button_id_cn,menu_id:item.menu_id},
      children: []
    })

    if (item.children&&item.children.length > 0) {
      item.children.forEach((subLevel, idx) => {
        arr[index]['children'].push({
          path: subLevel.route_path,
          name: subLevel.route_name,
          component: subLevel.route_component?() => import(`@/views/${subLevel.route_component}.vue`):{render: h => h('router-view')},
          redirect: subLevel.children[0].route_path,
          meta: {route_component:subLevel.route_component,title: subLevel.menu_name, hidden: subLevel.is_show,button_id_cn:subLevel.button_id_cn,menu_id:subLevel.menu_id},
          children:[]
        })
        if(subLevel.children.length>0){
          subLevel.children.forEach((sub2,idx2)=>{

            arr[index]['children'][idx]['children'].push({
              path: sub2.route_path,
              name: sub2.route_name,
              component: () => import(`@/views/${sub2.route_component}.vue`),
              meta: {route_component:sub2.route_component,title: sub2.menu_name, hidden: sub2.is_show,button_id_cn:sub2.button_id_cn,menu_id:sub2.menu_id},
              children:[]
            })
            if(sub2.children){
              let data=[]
              sub2.children.forEach((sub3,idx3)=>{
                arr[index]['children'][idx]['children'].push({
                  path: sub3.route_path,
                  name: sub3.route_name,
                  component: () => import(`@/views/${sub3.route_component}.vue`),
                  meta: {route_component:sub3.route_component,title: sub3.menu_name, hidden: sub3.is_show,button_id_cn:sub3.button_id_cn,highLight:sub2.route_name,parentTitle:sub2.menu_name,menu_id:sub3.menu_id},
                  children:[]
                })
              })

            }

          })
        }
      })
    }
  })
  routes[0].children[0].children=arr

  routes[0].redirect=routes[0].children[0].path
  routes.push({
        path: '*',
        component: _import('404'),
        name: 'NotFound',
        meta: {title: '404', hidden: true, menuActive: '/404', leaf: true, breadNum: 0}
      }
  )
  return routes
}
