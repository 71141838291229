import * as types from './mutation-types'
import router from '@/router'
import Menufilter from './menufilter'
import {resetRouter} from '@/router'
import Router from 'vue-router'
const matutations = {
  [types.SET_MENU_ID](state, data) {
    state.menu_id = data
  },
  [types.SET_ACTIVE_TOP_BAR](state, data) {
    state.activeTopBar = data
  },
  [types.SET_BUTTON](state, data) {
    state.buttonList = data
  },
  [types.SET_MENU](state, data) {
    state.menuList = data
    // let routes = []
    // // router.matcher = router.matcher;
    // console.log(111)
    //
    // routes=Menufilter(data) // 将后台的路由数据components转化成组件
    // // resetRouter()
    // // console.log(routes)
    // router.addRoutes(routes)  // 添加路由
    // console.log(router)
  },
  [types.SET_USER_INFO](state, data) {
    state.userInfo = data
  },

  [types.SET_IS_COLLAPSE](state, data) {
    state.is_collapse = data
  },
  [types.SET_TIPS](state, data) {
    state.tipsList = data
  },
  [types.SET_DISABLED](state, data) {
    state.saveDisabled = data
  },
  [types.SET_BASE64](state, data) {
    state.baseUrl = data
  },
}

export default matutations
