// import {DEV_HOST} from '@/api/config'
import axios from 'axios'
import {Message} from "element-ui";
import router from '@/router'
import store from "@/store";
const DEV_HOST = process.env.VUE_APP_API_URL

export function exportExcel(url, params, filename) {
  // let user = JSON.parse(sessionStorage.getItem('operate_user_info'))
  let token = store.state.userInfo.accessToken
  let tokenType = store.state.userInfo.tokenType
  axios({
    method: 'post',
    //线上运行
    // url: process.env.VUE_APP_API_URL + url,
    //本地运行
    url:  url,
    headers: {'blade-auth': tokenType + ' ' + token},
    data: params,
    responseType: 'blob' //二进制流
  }).then(res => {
    //this.$message.success('导出成功');
    console.log(res.data)
    // const blob = new Blob([res.data], {
    //   type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
    // })
    // const downloadElement = document.createElement('a');
    // const href = window.URL.createObjectURL(blob);
    // downloadElement.href = href;
    // downloadElement.download = filename + '.xls';
    // document.body.appendChild(downloadElement);
    // downloadElement.click();
    // document.body.removeChild(downloadElement);// 下载完成移除元素
    // window.URL.revokeObjectURL(href) // 释放掉blob对象
  }).catch(err => {
    this.$message({
      type: 'warning',
      message: '请求超时'
    });
  })
}

export function uploadImage(file, fileName, checkSize = '') {
  let reader = new FileReader()
  // let user = JSON.parse(sessionStorage.getItem('operate_user_info'));
  let token = store.state.userInfo.token
  reader.readAsDataURL(file)
  return new Promise((resolve, reject) => {
    reader.onload = function (e) {
      const instance = axios.create({
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authentication': token
        }
      });
      let data = new FormData();
      data.append(fileName, file)
      if (checkSize == 'checkSize') {
        let oImg = new Image();
        oImg.src = this.result;
        document.body.appendChild(oImg);
        oImg.onload = function () {
          let imgWidth = oImg.offsetWidth;
          let imgHeight = oImg.offsetHeight;
          document.body.removeChild(oImg);
          if (imgWidth == 180 && imgHeight == 128) {
            instance.post(`${DEV_HOST}api/agents/uploadImg`, data).then(res => {
              if (res.data.flag == 6666) {
                router.push({path: '/login'})
                Message({
                  message: '登录已失效，请重新登录！',
                  type: 'warning'
                })
              }
              resolve(res.data)
            }).catch(res => {
              reject(res);
            })
          } else {
            resolve({'flag': '233', 'msg': '请上传正确尺寸的图片'})
          }

        }
      } else {
        instance.post(`${DEV_HOST}api/agents/uploadImg`, data).then(res => {
          if (res.data.flag == 6666) {
            router.push({path: '/login'})
            Message({
              message: '登录已失效，请重新登录！',
              type: 'warning'
            })
          }
          resolve(res.data)
        }).catch(res => {
          reject(res);
        })
      }

    };
  })
}

//上传红头文件
export function upRedHeadFile(file, fileName) {
  // let user = JSON.parse(sessionStorage.getItem('operate_user_info'));
  let token = store.state.userInfo.token
  let files = Array.from(file)
  return new Promise((resolve, reject) => {
    let data = new FormData();
    for (let i in files) {
      data.append(`${fileName}[${i}]`, files[i])
    }
    const instance = axios.create({
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authentication': token
      }
    });
    instance.post(`${DEV_HOST}agents/upFile`, data).then(res => {

      resolve(res.data)
    }).catch(res => {
      reject(res);
    })
    // }
  })
}

export function coryText(text) {
  if (typeof document.execCommand !== 'function') {
    alert('复制失败，请长按复制')
    return
  }
  var dom = document.createElement('textarea')
  dom.value = text
  dom.setAttribute('style', 'display: block;width: 1px;height: 1px;')
  document.body.appendChild(dom)
  dom.select()
  var result = document.execCommand('copy')
  document.body.removeChild(dom)
  if (result) {
    return
  }
  if (typeof document.createRange !== 'function') {
    alert('复制失败，请长按复制')
    return
  }
  var range = document.createRange()
  var div = document.createElement('div')
  div.innerHTML = text
  div.setAttribute('style', 'height: 1px;fontSize: 1px;overflow: hidden;')
  document.body.appendChild(div)
  range.selectNode(div)
  const selection = window.getSelection()
  if (selection.rangeCount > 0) {
    selection.removeAllRanges()
  }
  selection.addRange(range)
  document.execCommand('copy')
  alert(`复制成功${text}`)
}
