<template>
  <el-dialog :title="title" :visible.sync="dialogVisible" :width="width" :before-close="handleClose" @open="open">
    <div class="total" v-if="dialog == 'sure'">合计: {{ totalCount }} 元</div>
    <slot></slot>
    <span slot="footer" class="dialog-footer">
      <el-button @click="sendback" type="danger" v-if="dialog == 'sure'" :disabled="disabled">退 回</el-button>
      <el-button type="primary" @click="sure" :disabled="disabled" v-if="dialog == 'sure' && status == true">确 认</el-button>
      <el-button @click="cancel" v-if="status == false">取 消</el-button>
      <el-button type="primary" @click="handleClick" :disabled="disabled" v-if="dialog != 'sure'">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
  export default {
    name: 'index',
    props: {
      /*
       * 是否显示
       * */
      dialogVisible: {
        type: Boolean,
        default: false,
      },
      // 是否是确认费用
      dialog: {
        type: String,
        default: '',
      },
      status: {
        sype: Boolean,
        default: true,
      },
      // 总计
      totalCount: {
        type: Number,
        default: 0,
      },
      /*
       * 弹窗的名字
       * */
      title: {
        type: String,
        default: '提示',
      },
      width: {
        type: String,
        default: '30%',
      },
      disabled: {
        type: Boolean,
        default: false,
      },
    },
    methods: {
      handleClose() {
        this.$emit('handleClose');
      },
      handleClick() {
        this.$emit('handleClick');
      },
      sure() {
        this.$emit('sure');
      },
      sendback() {
        this.$emit('sendback');
      },
      cancel() {
        this.$emit('cancel');
      },
      open() {
        this.$emit('open');
      },
      ConfirmDialog() {},
    },
  };
</script>

<style scoped>
/deep/ .el-dialog__header {
  text-align: center;
}
/deep/ .el-dialog__footer {
  text-align: right;
}
.total {
  font-size: 30px;
  color: red;
  font-weight: 600;
}
</style>
